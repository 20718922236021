import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Container, Button, Icon, Segment, Card, Header, Divider, Table, Label, Input, Grid, Dimmer, Loader } from "semantic-ui-react";
import { toast } from 'react-toastify';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as FINANCES_ACTIONS from '../../../actions/financesActions'
import TransactionDetails from "./TransactionDetails";
import FuseContentModal from "../../../components/generic/FuseContentModal";

const CustomDatePickerInput = React.forwardRef(({ value, onClick }, ref) => (
    <Input
        icon="calendar"
        iconPosition="left"
        placeholder="Select Date"
        onClick={onClick}
        value={value}
        ref={ref}
        style={{ borderRadius: '8px', borderColor: '#ccc' }}
    />
));

export function TenantFinancialPage(props) {
    const [state, setState] = useState({
        tenant: { ...props.tenant },
        denied: false,
        selectedCurrency: null,
        selectedType: null,
        selectedTag: null,
        selectedStatus: null,
        startDate: null,
        endDate: null,
        loading: false,
    });

    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const canReadFinanceData = () => {
        return (hasPermissions('Financial Transactions') && props.userPermissions["Financial Transactions"].actions.READ) || isAdmin()
    };

    useEffect(() => {
        if (!_.isEmpty(props.tenant)) {
            if (props.isPermissionsFetched) {
                if (canReadFinanceData()) {
                    setState({ ...state, loading: true });
                    props.fetchTenantFinances(props.tenant)
                        .then(() => {
                            setState({ ...state, loading: false });
                        })
                        .catch(() => {
                            setState({
                                denied: true,
                                loading: false,
                            });
                            toast.error('Failed to fetch tenant finances');
                        });
                }
                else {
                    setState({
                        denied: true,
                    })
                    toast.error('You do not have permission to view the tenant finances');
                }
            }
        }
    }, [props.tenant]);

    const tagMappings = {
        "MESSAGES": "message",
        "QUESTIONNAIRE DELIVERED": "delivery",
        "USER QUESTIONNAIRE REWARD": "user#reward",
        "QUESTIONNAIRE COMPLETED": "qnaireCompleted",
        "SUBSCRIPTION FEES": "subscriptionFee",
        "CV PURCHASES": "cvPurchase",
        "AI ANALYSIS": "aiAnalysis",
    };

    const reverseTagMappings = Object.fromEntries(
        Object.entries(tagMappings).map(([key, value]) => [value, key])
    );

    const handleFilterClick = (type, value) => {
        const mappedValue = tagMappings[value] || value;
        setState({
            ...state,
            selectedType: type === "type" ? value : null,
            selectedCurrency: type === "currency" ? value : null,
            selectedStatus: type === "status" ? value : null,
            selectedTag: type === "tag" ? value : null,
        });

        let filter = {
            filterType: type,
            filter: mappedValue,
        };
        props.fetchFilteredTenantTransactions(props.tenant, filter);
    };

    const _buildBalancesGroup = () => {
        let balances = props.tenantFinances?.currencies;
        let currencies = props.currencies;

        return (
            <div>
                <h3>Balances:</h3>
                {!_.isEmpty(balances) ? (
                    <Card.Group>
                        {Object.entries(balances).map(([currency, balance]) => {
                            const currencyData = currencies[currency];
                            const nextConversion = currencyData.denominations.find(denom => denom.next_conversion)?.next_conversion || 1;
                            const balanceAmount = balance / nextConversion;

                            return (
                                <Card
                                    key={currency}
                                    onClick={() => handleFilterClick("currency", currency)}
                                    style={{
                                        cursor: 'pointer',
                                        border: state.selectedCurrency === currency ? '2px solid #3A95A4' : '2px solid transparent',
                                    }}
                                >
                                    <Card.Content>
                                        <Card.Header>{currency}</Card.Header>
                                        <Card.Description>Balance: {balanceAmount}</Card.Description>
                                    </Card.Content>
                                </Card>
                            );
                        })}
                    </Card.Group>
                ) : (
                    <Label>No balances available. Please contact Wakamoso support if this is a mistake.</Label>
                )}
            </div>
        );
    };

    const handleDateChange = (type, date) => {
        setState({ ...state, [type]: date });
    };

    const clearFilters = () => {
        props.fetchTenantTransactions(props.tenant)
        setState({
            ...state,
            startDate: null,
            endDate: null,
            selectedCurrency: null,
            selectedType: null,
            selectedTag: null,
            selectedStatus: null,
        });
    };

    const _buildTransactionsGroup = () => {
        let filteredTransactions = props.tenantTransactions;

        // Filter by start date if selected
        if (state.startDate) {
            filteredTransactions = filteredTransactions.filter(
                (transaction) => new Date(transaction.created_at) >= state.startDate
            );
        }

        // Filter by end date if selected
        if (state.endDate) {
            filteredTransactions = filteredTransactions.filter(
                (transaction) => new Date(transaction.created_at) <= state.endDate
            );
        }

        // Sort filtered transactions by date
        const sortedTransactions = filteredTransactions.slice().sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
        });

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <h3>Transaction History:</h3>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <h4>Transaction Types:</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {["DEPOSIT", "TRANSFER", "WITHDRAW"].map(type => (
                                    <Button
                                        key={type}
                                        onClick={() => handleFilterClick("type", type)}
                                        basic={state.selectedType !== type}
                                        color={state.selectedType === type ? 'blue' : null}
                                        style={{
                                            border: '1px solid #3A95A4',
                                            borderRadius: '4px',
                                            marginRight: '5px',
                                            marginBottom: '5px',
                                            backgroundColor: state.selectedType === type ? '#3A95A4' : null,
                                            color: state.selectedType === type ? 'white' : null,
                                        }}
                                    >
                                        {type}
                                    </Button>
                                ))}
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <h4>Transaction Status: </h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {["SUCCESS", "FAILED"].map(status => (
                                    <Button
                                        key={status}
                                        onClick={() => handleFilterClick("status", status)}
                                        basic={state.selectedStatus !== status}
                                        color={state.selectedStatus === status ? 'blue' : null}
                                        style={{
                                            border: '1px solid #3A95A4',
                                            borderRadius: '4px',
                                            marginRight: '5px',
                                            marginBottom: '5px',
                                            backgroundColor: state.selectedStatus === status ? '#3A95A4' : null,
                                            color: state.selectedStatus === status ? 'white' : null,
                                        }}
                                    >
                                        {status}
                                    </Button>
                                ))}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <h4>Transaction Tag:</h4>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {["QUESTIONNAIRE DELIVERED", "QUESTIONNAIRE COMPLETED", "USER QUESTIONNAIRE REWARD", "SUBSCRIPTION FEES", "CV PURCHASES", "MESSAGES", "AI ANALYSIS"].map(tag => (
                                    <Button
                                        key={tag}
                                        onClick={() => handleFilterClick("tag", tag)}
                                        basic={state.selectedTag !== tag}
                                        color={state.selectedTag === tag ? 'blue' : null}
                                        style={{
                                            border: '1px solid #3A95A4',
                                            borderRadius: '4px',
                                            marginRight: '5px',
                                            marginBottom: '5px',
                                            backgroundColor: state.selectedTag === tag ? '#3A95A4' : null,
                                            color: state.selectedTag === tag ? 'white' : null,
                                        }}
                                    >
                                        {tag}
                                    </Button>
                                ))}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Label style={{ marginBottom: '8px' }}>Start Date:</Label>
                            <DatePicker
                                selected={state.startDate}
                                onChange={(date) => handleDateChange('startDate', date)}
                                endDate={state.endDate}
                                maxDate={state.endDate}
                                dateFormat="dd-MM-yyyy"
                                isClearable
                                customInput={<CustomDatePickerInput />}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Label style={{ marginBottom: '8px' }}>End Date:</Label>
                            <DatePicker
                                selected={state.endDate}
                                onChange={(date) => handleDateChange('endDate', date)}
                                startDate={state.startDate}
                                minDate={state.startDate}
                                dateFormat="dd-MM-yyyy"
                                isClearable
                                customInput={<CustomDatePickerInput />}
                            />
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Label style={{ marginBottom: '8px' }}>Clear Filters:</Label>
                            <div>
                                <Button compact className="warning" onClick={clearFilters}>Clear All</Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                {state.loading ? (
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                ) : sortedTransactions.length > 0 ? (
                    <Table compact size="small" striped celled equal width>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="nectaPrimaryBg">Date (DD-MM-YYYY):</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Transaction Type:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Transaction Tag:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Currency:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Balance Before:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Amount:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Balance After:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">Transaction Status:</Table.HeaderCell>
                                <Table.HeaderCell className="nectaPrimaryBg">View Details:</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {sortedTransactions.map((transaction, index) => {
                                const currencyData = props.currencies[transaction?.currency];
                                const nextConversion = currencyData?.denominations.find(denom => denom.next_conversion)?.next_conversion || 1;

                                const formatter = new Intl.NumberFormat('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });

                                const amountInBaseCurrency = formatter.format(Math.abs(transaction.amount / nextConversion));
                                const balanceBeforeInBaseCurrency = formatter.format(Math.abs(transaction.balance_before / nextConversion));
                                const balanceAfterInBaseCurrency = formatter.format(Math.abs(transaction.balance_after / nextConversion));

                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>
                                            {moment
                                                .utc(transaction.created_at)
                                                .format("DD-MM-YYYY hh:mm:ss a")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {transaction.transaction_type}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {(transaction.transaction_type === 'DEPOSIT' || transaction.transaction_type === 'WITHDRAW')
                                                ? transaction.description
                                                : (reverseTagMappings[transaction.tag] || transaction.tag)
                                            }
                                        </Table.Cell>
                                        <Table.Cell>
                                            {transaction.currency}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {balanceBeforeInBaseCurrency}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <span
                                                style={{
                                                    color:
                                                        transaction.operation === "DEBIT"
                                                            ? "red"
                                                            : "green",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {transaction.operation === "DEBIT"
                                                    ? "- "
                                                    : "+ "}
                                                {amountInBaseCurrency}
                                            </span>
                                        </Table.Cell>{" "}
                                        <Table.Cell>
                                            {balanceAfterInBaseCurrency}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {transaction.status}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <FuseContentModal
                                                header={`Transaction: ${transaction.transaction_id}`}
                                                content={
                                                    <TransactionDetails
                                                        currencies={props.currencies}
                                                        transaction={transaction}
                                                        bdaction={"edit"}
                                                    />
                                                }
                                                size={"fullscreen"}
                                                trigger={
                                                    <Button
                                                        compact
                                                        className="warning"
                                                        size="small"
                                                    >
                                                        View
                                                    </Button>
                                                }
                                                onOpen={() => {
                                                    setState((state) => ({
                                                        ...state,
                                                        isViewTransactionOpen: true,
                                                        selectedTransaction: transaction,
                                                    }));
                                                }}
                                                onClose={() => {
                                                    setState((state) => ({
                                                        ...state,
                                                        isViewTransactionOpen: false,
                                                        selectedTransaction: {},
                                                    }));
                                                }}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                ) : (
                    <Label style={{ marginTop: '10px' }}>No Transaction History Available</Label>
                )}
            </div>
        );
    };


    if (state.denied) {
        return (
            <Container>
                <Segment>
                    <Header icon>
                        <Icon name='ban' color="red" />
                        You do not have permissions to view tenant finances
                    </Header>
                </Segment>
            </Container>
        );
    }

    return (
        <Container fluid style={{ paddingBottom: '50px' }}>
            <h3>Tenant Finances:</h3>
            <Segment raised>
                {_buildBalancesGroup()}
                <Divider />
                {_buildTransactionsGroup()}
            </Segment>
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.tenantManagement.activeTenant,
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        tenantFinances: state.tenantFinances.tenantBalances,
        tenantTransactions: state.tenantFinances.tenantTransactions,
        currencies: state.tenantFinances.currencies,
    };
};

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        fetchTenantFinances: (tenant) => dispatch(FINANCES_ACTIONS.fetchTenantFinances(tenant)),
        fetchFilteredTenantTransactions: (tenant, filter) => dispatch(FINANCES_ACTIONS.fetchFilteredTenantTransactions(tenant, filter)),
        fetchTenantTransactions: (tenant, filter) => dispatch(FINANCES_ACTIONS.fetchTenantTransactions(tenant, filter)),
    })
)(TenantFinancialPage);
