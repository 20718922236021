import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { toast } from 'react-toastify';
import { Container, Dimmer, Form, Header, Icon, Loader, Popup, Segment } from "semantic-ui-react";

import * as FINANCES_ACTIONS from '../../../actions/financesActions'

const convertPricesToRand = (prices) => {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    if (!prices || !prices.prices) return prices;
    return {
        ...prices,
        prices: {
            ...prices.prices,
            qnaireCost: formatter.format(Math.abs(prices.prices.qnaireCost / 100)),
            qnaireCompletionCost: formatter.format(Math.abs(prices.prices.qnaireCompletionCost / 100)),
            appMessaging: formatter.format(Math.abs(prices.prices.appMessaging / 100)),
            whatsAppMessaging: formatter.format(Math.abs(prices.prices.whatsAppMessaging / 100)),
            aiAnalysis: formatter.format(Math.abs(prices.prices.aiAnalysis / 100)),
            subscriptionFee: formatter.format(Math.abs(prices.prices.subscriptionFee / 100)),
            cvSearch: formatter.format(Math.abs(prices.prices.cvSearch / 100)),
            minimumBalance: formatter.format(Math.abs(prices.prices.minimumBalance / 100)),
        },
    };
};

export function TenantPricingPage(props) {

    const [state, setState] = useState({
        tenant: {
            ...props.tenant,
            baseQnaires: [],
        },
        tenantPrices: {},
        originalPrices: {},
    });

    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const canReadTenant = () => {
        return hasPermissions('Manage Tenant') && props.userPermissions['Manage Tenant'].actions.READ || isAdmin();
    };

    const CustomLabel = ({ text, className }) => (
        <label className={`custom-label ${className}`}>
            {text}
        </label>
    );

    useEffect(() => {
        setState({ ...state, loading: true });
        if (!_.isEmpty(props.tenant)) {
            if (props.isPermissionsFetched) {
                if (canReadTenant()) {
                    const tenantPricesInRand = convertPricesToRand(props.tenant);
                    setState({
                        denied: false,
                        loading: false,
                        tenantPrices: tenantPricesInRand,
                    })
                }
                else {
                    setState({
                        denied: true,
                        loading: false,
                    })
                    toast.error('You do not have permission to view the tenant information');
                }
            }
        }
        // props.fetchTenantCurrencies(props.tenant);
    }, [props.tenant]);

    // const [currencyList, setCurrencyList] = useState([]);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     if (props.tenantCurrencies) {
    //     const updatedCurrencyList = Object.entries(props.tenantCurrencies).map(([key, value]) => ({
    //         key,
    //         value,
    //         text: key, 
    //     }));
    //     setCurrencyList(updatedCurrencyList);
    //     setLoading(false); 
    //     }
    // }, [props.tenantCurrencies]); 

    const _buildTenantPricing = () => {
        return (
            <div>
                {state.loading ? (
                    <Dimmer active inverted>
                        <Loader>Loading...</Loader>
                    </Dimmer>
                ) : (
                    <>
                        <CustomLabel text="Operating Costs" className="nectaPrimaryBg" />
                        <Form.Group widths="equal">
                            <Popup
                                content="The cost of every questionnaire sent to users."
                                trigger={
                                    <Form.Field>
                                        <label>
                                            Questionnaire Delivery Costs (
                                            {props.tenant.operatingCountry?.key || 'ZAR'}
                                            )
                                        </label>
                                        <input
                                            name="qnaireCost"
                                            placeholder="Questionnaire delivery cost"
                                            value={state.tenantPrices.prices?.qnaireCost || ""}
                                        />
                                    </Form.Field>
                                }
                            />
                            <Popup
                                content="The cost to send a message or questionnaire to the Wakamoso Mobile App."
                                trigger={
                                    <Form.Field>
                                        <label>App Messaging (
                                        {props.tenant.operatingCountry?.key || 'ZAR'}
                                        ):</label>
                                        <input
                                            name="appMessaging"
                                            placeholder="App messaging"
                                            value={state.tenantPrices.prices?.appMessaging || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                            <Popup
                                content="The cost to send a message or questionnaire via WhatsApp (User does not have Mobile App installed)"
                                trigger={
                                    <Form.Field>
                                        <label>WhatsApp Messaging (
                                        {props.tenant.operatingCountry?.key || 'ZAR'}
                                        ):</label>
                                        <input
                                            name="whatsAppMessaging"
                                            placeholder="WhatsApp Messaging"
                                            value={state.tenantPrices.prices?.whatsAppMessaging || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                        </Form.Group>
                        <CustomLabel text="Usage Fees" className="nectaPrimaryBg" />
                        <Form.Group widths="equal">
                            <Popup
                                content="The fee to run an AI analysis on a data set within your ecosystem."
                                trigger={
                                    <Form.Field>
                                        <label>AI Analysis (
                                        {props.tenant.operatingCountry?.key || 'ZAR'}
                                        ):</label>
                                        <input
                                            name="aiAnalysis"
                                            placeholder="AI analysis"
                                            value={state.tenantPrices.prices?.aiAnalysis || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                            <Popup
                                content="The monthly cost per user linked to your tenant dashboard. "
                                trigger={
                                    <Form.Field>
                                        <label>Subscription Fee (
                                            {props.tenant.operatingCountry?.key || 'ZAR'}
                                        ):</label>
                                        <input
                                            name="subscriptionFee"
                                            placeholder="Subscription Fee"
                                            value={state.tenantPrices.prices?.subscriptionFee || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                            <Popup
                                content="The cost to access the personal information linked to a CV that you wish to access."
                                trigger={
                                    <Form.Field>
                                        <label>CV Purchase Fee (
                                        {props.tenant.operatingCountry?.key || 'ZAR'}
                                        ):</label>
                                        <input
                                            name="cvSearch"
                                            placeholder="CV Search"
                                            value={state.tenantPrices.prices?.cvSearch || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                            <Popup
                                content="The % of the CV Purchase Fee paid to the data subject (person whose personal information we share with you)"
                                trigger={
                                    <Form.Field>
                                        <label>CV Owner Fee Split (%):</label>
                                        <input
                                            name="cvOwnerSplit"
                                            placeholder="CV Owner Split"
                                            value={state.tenantPrices.prices?.cvOwnerSplit || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                        </Form.Group>
                        <CustomLabel text="Minimum Cash Balance" className="nectaPrimaryBg" />
                        <Form.Group widths="equal">
                            <Popup
                                content="Minimum cash balance required."
                                trigger={
                                    <Form.Field>
                                        <label> Minimum Cash Balance (
                                        {props.tenant.operatingCountry?.key || 'ZAR'}
                                        ):</label>
                                        <input
                                            type="text"
                                            name="minimumBalance"
                                            placeholder="Minimum Cash Balance"
                                            value={state.tenantPrices.prices?.minimumBalance || ""}

                                        />
                                    </Form.Field>
                                }
                            />
                        </Form.Group>
                    </>
                )}
            </div>
        );
    }

    if (state.denied) {
        return (
            <Container>
                <Segment>
                    <Header icon>
                        <Icon name='ban' color="red" />
                        You do not have permissions to view tenant pricing
                    </Header>
                </Segment>
            </Container>
        );
    }

    return (
        <Container fluid>
            <Form size='large' style={{ paddingBottom: "20px" }}>
                <h3>Tenant Finances:</h3>
                <Segment>
                    {_buildTenantPricing()}
                </Segment>
            </Form>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    tenant: state.tenantManagement.activeTenant,
    isPermissionsFetched: state.isPermissionsFetched === true,
    tenantFinances: state.tenantFinances.tenantFinances,
    currencies: state.tenantFinances.currencies,
    // tenantCurrencies: state.tenantFinances.tenantBalances.currencies
});

export default connect(mapStateToProps,
    (dispatch, ownProps) => ({
        fetchTenantFinances: (tenant) => dispatch(FINANCES_ACTIONS.fetchTenantFinances(tenant)),
        // fetchTenantCurrencies : (tenant) => dispatch(FINANCES_ACTIONS.fetchTenantCurrency(tenant))
    })
)(TenantPricingPage);