import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Segment, Button, List, Dimmer, Confirm, Label, Icon } from "semantic-ui-react";
import QuestionDetails from './QuestionDetails';
import FuseContentModal from '../../../../components/generic/FuseContentModal';

import * as QUESTIONNAIRE_ACTIONS from "../../../../actions/questionaireActions";
import * as QUESTION_ACTIONS from '../../../../actions/questionActions';
import * as CONDITION_ACTIONS from '../../../../actions/conditionActions';

class QuestionaireQuestionItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question: this.props.question,
      showConfirmDelete: false
    };
  }

  hasPermissions(category) {
    return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
  }

  isAdmin() {
    return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
  }

  canReadQuestions() {
    return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.READ || this.isAdmin()
  }

  canCreateQuestions() {
    return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.CREATE || this.isAdmin()
  }

  canUpdateQuestions() {
    return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.UPDATE || this.isAdmin()
  }

  canDeleteQuestions() {
    return this.hasPermissions('Questions') && this.props.userPermissions['Questions'].actions.DELETE || this.isAdmin()
  }

  handleShow = () => this.setState({ active: true })
  handleHide = () => this.setState({ active: false })

  confirmDeactivate() {
    let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";

    return (
      <Confirm
        header='Warning:'
        content={contentMessage}
        open={this.state.showConfirmIsActive}
        onCancel={() => this.setState((state) => ({ ...state, showConfirmIsActive: false }))}
        onConfirm={() => {
          this.setState({ showConfirmDelete: true })
          this.setState({ showConfirmIsActive: false })
        }}
      />
    );
  }

  confirmDelete() {
    return (
      <Confirm
        content={`Are you sure you want to delete question: ${this.props.question.question}?`}
        open={this.state.showConfirmDelete}
        onCancel={() => this.setState({ showConfirmDelete: false })}
        onConfirm={() => {
          this.props.deactiveQuestionaireAction(this.props.questionaire);
          this.props.deleteQuestion(this.props.question, this.props.condition, this.props.questionaire.name);
        }
        }
      />
    );
  }

  render() {
    const isBaseQnaire = this.props.questionaire.isBaseQnaire;
    const isRootTenant = this.props.tenant.isRoot === true || false;
    return (
      <List.Item>
        {this.confirmDelete()}
        {this.confirmDeactivate()}
        <Dimmer.Dimmable as={Segment} dimmed={this.state.active}
          vertical
          className='clickable'
          onMouseEnter={this.handleShow}
          onMouseLeave={this.handleHide}
        >
          <Dimmer inverted active={this.state.active}>
            {this.canUpdateQuestions() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
              <FuseContentModal
                header='Edit question'
                content={<QuestionDetails questionaire={this.props.questionaire} question={this.props.question} sections={this.props.sections} />}
                trigger={<Button className='warning' icon='edit'></Button>}
                onClose={() => {
                  this.props.clearSelectedCondition();
                }}
              />)}
            {this.canDeleteQuestions() && (!isBaseQnaire || (isBaseQnaire && isRootTenant)) && (
              <Button disabled={!this.props.question.isUserCreated} className='danger' icon='trash' style={{ float: 'right' }}
                // onClick={() => this.setState({ showConfirmDelete: true })}>
                onClick={() => {
                  const isQuestionnaireActive = this.props.questionaire.status == "active" || this.props.questionaire.status == "underReview";

                  if (isQuestionnaireActive) {
                    this.setState((state) => ({ ...state, showConfirmIsActive: true }));
                  } else {
                    this.setState({ showConfirmDelete: true })
                  }
                }}>
              </Button>
            )}
          </Dimmer>
          {this.props.question.question}
        </Dimmer.Dimmable>
        <div style={{ margin: 5 }}>
          <Label size='mini' as='a' tag color='blue'> Sequence: {this.props.question.sequence}</Label>
          {this.props.question.isConditional && (
            <Label size='mini' as='a' tag color='green'>
              Conditional
            </Label>
          )}
          {this.props.question.canRedirect && (
            <Label size='mini' as='a' tag color='green'>
              Redirect
            </Label>
          )}
          {this.props.question.canRepeat && (
            <Label size='mini' as='a' tag color='green'>
              Repeats From Sequence: {this.props.question.extraInfo}
            </Label>
          )}
          {this.props.question.whatsAppOnly && (
            <Label size='mini' as='a' tag color='green'>
              WhatsApp Only
            </Label>
          )}
          {this.props.question.isPersonalInfo && (
            <Label size='mini' as='a' tag color='green'>
              Personal
            </Label>
          )}
        </div>
      </List.Item>

    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    question: (_.isEmpty(state.questions)) ? {} : Object.values(state.questions.filter(x => x.id === ownProps.question.id))[0],
    sections: _.isEmpty(state.sections) ? [] : _.orderBy(Object.values(state.sections)),
    tenant: state.tenantManagement.activeTenant,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,

  };
}


// // const mapStateToProps = (state, ownProps) => {
//   return {
//     question: (_.isEmpty(state.questions)) ? {} : Object.values(state.questions.filter(x => x.id === ownProps.question.id))[0],
//   };
// }

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
    deleteQuestion: (question, condition, questionaireName) => dispatch(QUESTION_ACTIONS.questionDeleteAction(question, condition, questionaireName)),
    clearSelectedCondition: () => dispatch(CONDITION_ACTIONS.conditionClearSelectedAction()),
  })
)(QuestionaireQuestionItem);