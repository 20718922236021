import { getCvSearchResults, buyCV } from "../webservices/cvSearchWS";
import { fetchItemsWS } from "../webservices/fetchWS";
import { createItemWs, updateItemWs } from "../webservices/saveWS";
import { createTenantWalletWs, createWalletTenantWs } from "../webservices/walletWS";
import { CVSearchlogDTO } from "./DTOs/CVSearchlogDTO";
import { UserDTO } from "./DTOs/UserDTO";
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import _ from "lodash";
import { toast } from "react-toastify";

export const CV_SEARCH_ACTION = "CV_SEARCH_ACTION";

export const cvSearchAction = (payload, tenant, setLoading) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SAVE_IN_PROGRESS });
            setLoading(true);
            const result = await getCvSearchResults(payload, getState);
            dispatch({ type: CV_SEARCH_ACTION, payload: result.data });
            setLoading(false);
            if (_.isEmpty(result.data.data)) {
                toast.error("We are unable to find any results to match your requirements. Please try again.");
            }
            return logCVSearchAction(payload, tenant, result.data)(dispatch, getState);
        } catch (err) {
            toast.error("There was an error performing the search.  Please try again later.");
            dispatch({ type: SAVE_PROCESS_DONE });
            setLoading(false);
            throw err;
        }
    };
};

export const logCVSearchAction = (payload, tenant, resultData) => {
    return (dispatch, getState) => {
        const cvLog = new CVSearchlogDTO(payload, tenant, resultData);
        createItemWs(cvLog, getState)
            .then((result) => {
                return result.data
            })
            .catch((err) => {
                dispatch({ type: SAVE_PROCESS_DONE });
                throw err;
            })
    }
}
////////////////////////////////////////////////////////////////////////////////////////
//CV Purchasing Actions
export const purchaseCVAction = (cv, cvSearchQuery, setLoading) => {

    let matchPercentage = Math.round(((cv._score - 0) / (1 - 0)) * 100 / 5) * 5;

    matchPercentage = Math.min(Math.max(0, matchPercentage), 100);

    return async (dispatch, getState) => {
        let payload = {
            userId: cv._source.userid,
            // userId: "27787792306",
            qnaireId: cv._source.questionaireid,
            cvSearchQuery: cvSearchQuery,
            matchPercentage: matchPercentage,
        }
        try {
            setLoading(true);
            const result = await buyCV(payload, getState);
            if (result === 404) {
                return getUserDetails(cv, setLoading, dispatch, getState);
            }
            setLoading(false);
            toast.success("Successfully purchased cv!");
        } catch (err) {
            console.log(err);
            toast.error("There was an error purchasing the cv. Please try again later.");
            dispatch({ type: SAVE_PROCESS_DONE });
            setLoading(false);
        }
    }
}

export const getUserDetails = async (cv, setLoading, dispatch, getState) => {
    const queryParams = {
        PK: `USERS`,
        // SK: `USER%2327787792306`,
        SK: `USER%23${cv._source.userid}`,
    };

    try {
        const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
        const user = result.Items.filter(item => item.status !== "isDeleted");
        return createWalletUserAction(user[0], cv, setLoading)(dispatch, getState);
    } catch (err) {
        setLoading(false);
        console.log(err);
        throw err;
    }
}

export const createWalletUserAction = (user, cv, setLoading) => {
    let data = {
        phoneNumber: user.id,
    }
    return (dispatch, getState) => {
        dispatch({ type: SAVE_IN_PROGRESS });
        createWalletTenantWs(data, getState)
            .then((result) => {
                const userData = result.data;
                return createUserWalletAction(userData, user, cv, setLoading)(dispatch, getState);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
            });
    }
}

export const createUserWalletAction = (userData, user, cv, setLoading) => {
    return (dispatch, getState) => {
        let data = {
            userId: userData.user_id,
        }
        dispatch({ type: SAVE_IN_PROGRESS });
        createTenantWalletWs(data, getState)
            .then((result) => {
                const walletData = result.data;

                const newUserData = {
                    PK: user.PK,
                    SK: user.SK,
                    id: user.id,
                    contact_nr: user.id,
                    walletUserId: walletData.user_id,
                    walletId: walletData.wallet_id,
                };

                const newTenant = new UserDTO(newUserData);
                updateItemWs(newTenant, getState)
                    .then((result) => {
                        return purchaseCVAction(cv, setLoading)(dispatch, getState);
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch({ type: SAVE_PROCESS_DONE });
                    });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: SAVE_PROCESS_DONE });
            });
    }
}
////////////////////////////////////////////////////////////////////////////////////////
//View Selected CV actions
export const FETCHING_SELECTED_CV_ACTION = 'FETCHING_SELECTED_CV_ACTION';
export const FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION = 'FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION';
export const FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION = 'FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION';
export const FETCH_SELECTED_CV_ERROR_ACTION = 'FETCH_SELECTED_CV_ERROR_ACTION';

export const fetchSelectedCVAction = (cv, tenant, setLoading) => {
    const qnaireParams = {
        // PK: `USER%2327733793291`,
        PK: `USER%23${cv._source.userid}`,
        SK: `USERQNAIRE%23`,
    };

    const filterData = (items) => {
        let filteredItems = items.filter(item => (
            item.op !== 'd' &&
            item.completionPercentage === 100 &&
            item.questionaireId === cv._source.questionaireid
        ));

        let newestItem = filteredItems.reduce((maxItem, currentItem) => {
            let maxDate = maxItem ? maxItem.createdAt.substring(0, 14) : '00000101T000000';
            let currentDate = currentItem.createdAt.substring(0, 14);

            return maxDate < currentDate ? currentItem : maxItem;
        }, null);

        return newestItem;
    };

    return (dispatch, getState) => {
        setLoading(true);
        dispatch({ type: FETCHING_SELECTED_CV_ACTION });
        fetchItemsWS('get-dynamodb', qnaireParams, getState)
            .then((result) => {
                let items = filterData(result.Items);
                const userQnaireParams = {
                    PK: `${items.tenantId}%23USERQNAIRE%23${items.id}`,
                    SK: `ANSWER`
                }
                const QnaireParams = {
                    PK: `${items.tenantId}%23QNAIRE%23${cv._source.questionaireid}`,
                }
                fetchItemsWS('get-dynamodb', userQnaireParams, getState)
                    .then((result) => {
                        let items = result.Items.filter((item) => item.status !== 'isDeleted');
                        dispatch({ type: FETCH_SELECTED_CV_ANSWERS_SUCCESS_ACTION, payload: items });
                        fetchItemsWS('get-dynamodb', QnaireParams, getState)
                            .then((result) => {
                                let items = result.Items.filter((item) => item.status !== 'isDeleted');
                                dispatch({ type: FETCH_SELECTED_CV_QNAIRE_SUCCESS_ACTION, payload: items });
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.log(err);
                                dispatch({ type: FETCH_SELECTED_CV_ERROR_ACTION, err });
                                setLoading(false);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch({ type: FETCH_SELECTED_CV_ERROR_ACTION, err });
                        setLoading(false);
                    });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_SELECTED_CV_ERROR_ACTION, err });
                setLoading(false);
            });
    };
}

export const FETCHING_SELECTED_CV_STATUS_ACTION = 'FETCHING_SELECTED_CV_STATUS_ACTION';
export const FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION = 'FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION';
export const FETCH_SELECTED_CV_STATUS_ERROR_ACTION = 'FETCH_SELECTED_CV_STATUS_ERROR_ACTION';

export const checkCVOwnStatusAction = (tenant, cv) => {
    const queryParams = {
        PK: `${tenant.id}%23BOUGHTCVS`,
        SK: `USER%23${cv._source.userid}`,
        // SK: `USER%2327787792306`,

    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_SELECTED_CV_STATUS_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                let items = result.Items.filter((item) => item.status !== 'isDeleted');
                dispatch({ type: FETCH_SELECTED_CV_STATUS_SUCCESS_ACTION, payload: items });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_SELECTED_CV_STATUS_ERROR_ACTION, err });
            });
    };
}
////////////////////////////////////////////////////////////////////////////////////////
//CV Search Log Actions
export const FETCHING_CVSEARCHLOG_ACTION = 'FETCHING_CVSEARCHLOG_ACTION';
export const FETCH_CVSEARCHLOG_SUCCESS_ACTION = 'FETCH_CVSEARCHLOG_SUCCESS_ACTION';
export const FETCH_CVSEARCHLOG_ERROR_ACTION = 'FETCH_CVSEARCHLOG_ERROR_ACTION';

export const fetchCVSearchLogsAction = (tenant) => {
    const queryParams = {
        PK: `${tenant.id}%23CVSEARCH`,
        SK: `CVSEARCH%23`
    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_CVSEARCHLOG_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                let items = result.Items.filter((item) => item.status !== 'isDeleted');
                dispatch({ type: FETCH_CVSEARCHLOG_SUCCESS_ACTION, payload: items });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_CVSEARCHLOG_ERROR_ACTION, err });
            });
    };
}
////////////////////////////////////////////////////////////////////////////////////////
//Bought CVs Actions
export const FETCHING_BOUGHT_CVS_ACTION = 'FETCHING_BOUGHT_CVS_ACTION';
export const FETCH_BOUGHT_CVS_SUCCESS_ACTION = 'FETCH_BOUGHT_CVS_SUCCESS_ACTION';
export const FETCH_BOUGHT_CVS_ERROR_ACTION = 'FETCH_BOUGHT_CVS_ERROR_ACTION';

export const fetchBoughtCVsAction = (tenant) => {
    const queryParams = {
        PK: `${tenant.id}%23BOUGHTCVS`,
        SK: `USER%23`
    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_BOUGHT_CVS_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                let items = result.Items.filter((item) => item.status !== 'isDeleted');
                dispatch({ type: FETCH_BOUGHT_CVS_SUCCESS_ACTION, payload: items });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_BOUGHT_CVS_ERROR_ACTION, err });
            });
    };
}

export const CLEAR_USER_CV = 'CLEAR_USER_CV';
export const clearUserCV = (dispatch) => {
    return (dispatch, getState) => {
        dispatch({ type: CLEAR_USER_CV })
    };
};
