import { TenantDTO } from './DTOs/TenantDTO';
import _ from "lodash";
import { Auth } from 'aws-amplify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { fetchItemsWS } from '../webservices/fetchWS';
import { createTenantWalletWs, createWalletTenantWs } from '../webservices/walletWS';
import { updateItemWs } from '../webservices/saveWS';
import { toast } from 'react-toastify';

export const RESET_STATE_ACTION = 'RESET_STATE_ACTION';

export const resetState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_ACTION });
  };
}

export const FETCHING_ALL_TENANTS_ACTION = 'FETCHING_ALL_TENANTS_ACTION';
export const FETCH_ALL_TENANTS_SUCCESS_ACTION = 'FETCH_ALL_TENANTS_SUCCESS_ACTION';
export const FETCH_ALL_TENANTS_ERROR_ACTION = 'FETCH_ALL_TENANTS_ERROR_ACTION';

export const fetchAllTenantsAction = () => {
  const queryParams = {
    PK: `TENANTS`,
    SK: `TENANT%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_ALL_TENANTS_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const items = result.Items;
        dispatch({ type: FETCH_ALL_TENANTS_SUCCESS_ACTION, payload: items });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_ALL_TENANTS_ERROR_ACTION, err });
      });
  };
}

export const SET_ACTIVE_TENANT_ACTION = 'SET_ACTIVE_TENANT_ACTION';

export const setActiveTenantAction = (activeTenant) => {
  return (dispatch) => {

    const tenant = {
      ...activeTenant,
      tenantId: activeTenant.id,
      tenantName: activeTenant.name,
      tenantDescription: activeTenant.description,
    };

    dispatch({ type: SET_ACTIVE_TENANT_ACTION, payload: tenant });
  };
}

export const FETCHING_SOURCE_TENANT_ACTION = 'FETCHING_SOURCE_TENANT_ACTION';
export const FETCH_SOURCE_TENANT_SUCCESS_ACTION = 'FETCH_SOURCE_TENANT_SUCCESS_ACTION';
export const FETCH_SOURCE_TENANT_ERROR_ACTION = 'FETCH_SOURCE_TENANT_ERROR_ACTION';

export const fetchSourceTenantAction = (payload) => {
  return async (dispatch, getState) => {
    const queryParams = {
      PK: `TENANTS`,
      SK: `TENANT%23${payload.groupName}`,
    };

    dispatch({ type: FETCHING_SOURCE_TENANT_ACTION });
    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const tenantData = result.Items[0];

      if (tenantData.status === "disabled") {
        await Auth.signOut();
        window.location.reload();
      }

      const tenant = {
        ...tenantData,
        tenantId: tenantData.id,
        tenantName: tenantData.name,
        tenantDescription: tenantData.description,
      };

      dispatch({ type: FETCH_SOURCE_TENANT_SUCCESS_ACTION, payload: tenant });
      dispatch(setActiveTenantAction(tenant));

      const isRoot = tenant.hasOwnProperty('isRoot') && tenant['isRoot'] === true;
      if (isRoot) dispatch(fetchAllTenantsAction());
    } catch (err) {
      dispatch({ type: FETCH_SOURCE_TENANT_ERROR_ACTION, err });
    }
  };
};


const extractPermissions = (userRoles) => {
  let extractedPermissions = {};

  userRoles.forEach((role) => {
    if (role.permissions && role.permissions.length > 0) {
      role.permissions.forEach((permission) => {

        if (extractedPermissions.hasOwnProperty(permission.category)) {
          const exsitingActions = extractedPermissions[permission.category].actions
          const newActions = permission.actions

          const combinedActions = combinePermissionActions(exsitingActions, newActions)

          extractedPermissions[permission.category] = {
            category: permission.category,
            actions: combinedActions,
          };

        }
        else {
          extractedPermissions[permission.category] = {
            category: permission.category,
            actions: permission.actions,
          };
        }
      });
    }
  });

  return extractedPermissions;
};

export const SET_PERMISSIONS_FETCHED_ACTION = 'SET_PERMISSIONS_FETCHED_ACTION';

const setPermisionsFetchedAction = (isFetched) => {
  return (dispatch) => {
    dispatch({ type: SET_PERMISSIONS_FETCHED_ACTION, payload: isFetched });
  };
}

const combinePermissionActions = (p1, p2) => {
  const keys1 = Object.keys(p1);
  const keys2 = Object.keys(p2);

  const combinedKeys = [...new Set([...keys1, ...keys2])]

  const resultAction = {}

  combinedKeys.forEach((key) => {
    const action = p1[key] === true || p2[key] === true

    resultAction[key] = action
  })

  return resultAction
};

// Action to fetch user roles and dispatch relevant actions
export const FETCHING_AUTH_USER_ROLES_ACTION = 'FETCHING_AUTH_USER_ROLES_ACTION';
export const FETCH_AUTH_USER_ROLES_SUCCESS_ACTION = 'FETCH_AUTH_USER_ROLES_SUCCESS_ACTION';
export const FETCH_AUTH_USER_ROLES_ERROR_ACTION = 'FETCH_AUTH_USER_ROLES_ERROR_ACTION';

export const fetchAuthUserRoleAction = (tenantData) => {
  const queryParams = {
    PK: `USER%23${tenantData.userName}`,
    SK: `ROLE%23`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_AUTH_USER_ROLES_ACTION });

    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        let returnItems = result.Items;

        returnItems = returnItems.filter((item) => item.op !== "d")

        const extractedPermissions = extractPermissions(returnItems);

        dispatch({
          type: FETCH_AUTH_USER_ROLES_SUCCESS_ACTION,
          payload: {
            userRoles: returnItems,
            permissions: extractedPermissions,
          },
        });
        dispatch(setPermisionsFetchedAction(true))
        dispatch(fetchSourceTenantAction(tenantData))
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_AUTH_USER_ROLES_ERROR_ACTION, err });
      });
  };
};

export const AUTHDATA_ADD_ACTION = 'AUTHDATA_ADD_ACTION';

export const saveAuthDataAction = (authData) => {
  return (dispatch, getState) => {
    dispatch({ type: AUTHDATA_ADD_ACTION, payload: authData });
  }
}

export const tenantChecksAction = (payload) => {
  const queryParams = {
    PK: `TENANTS`,
    SK: `TENANT%23${payload.groupName}`,
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_AUTH_USER_ROLES_ACTION });
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const tenantData = result.Items[0];


        //Check if the tenant accpeted the tos.
        const currentPath = window.location.pathname;
        if (!tenantData.subAgreement) {
          toast.error("Please read and accept the Wakamoso terms of service!");
          if (currentPath !== "/tos") {
            window.location.href = "/tos";
          }
          return
        } else if (
          _.isEmpty(tenantData.contactDetails) ||
          _.isEmpty(tenantData.address) ||
          _.isEmpty(tenantData.operatingCountry)
        ) {
          toast.error("Please complete tenant information page!");
          if (currentPath !== "/Admin/Manage%20Tenant") {
            window.location.href = "/Admin/Manage Tenant";
          }
          return
        }

        //Check if the tenant has a wallet
        if (tenantData.walletId === '' || tenantData.walletId === undefined) {
          dispatch(createWalletTenantAction(tenantData))
        }
        return
      })
      .catch((err) => {

      });
  }
}

// Create Wallet Start //
export const createWalletTenantAction = (tenantData) => {
  return (dispatch, getState) => {
    let data = {
      tenantId: tenantData.id,
      tenantName: tenantData.name,
    }
    dispatch({ type: SAVE_IN_PROGRESS });
    createWalletTenantWs(data, getState)
      .then((result) => {
        const userData = result.data;
        return createTenantWalletAction(userData, tenantData)(dispatch, getState);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
      });
  }
}

export const createTenantWalletAction = (userData, tenantData) => {
  return (dispatch, getState) => {

    const defaultCurrency = tenantData.operatingCountry.key;


    let data = {
      name: userData.tenantName,
      description: 'Wakamoso tenant wallet',
      userId: userData.user_id,
      defaultCurrencies: [defaultCurrency]
    }
    dispatch({ type: SAVE_IN_PROGRESS });
    createTenantWalletWs(data, getState)
      .then((result) => {
        const walletData = result.data;

        const newTenantData = {
          PK: tenantData.PK,
          SK: tenantData.SK,
          id: tenantData.id,
          walletUserId: walletData.user_id,
          walletId: walletData.wallet_id,
        };

        const newTenant = new TenantDTO(newTenantData);
        updateItemWs(newTenant, getState)
          .then((result) => {
            dispatch({ type: SAVE_PROCESS_DONE });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: SAVE_PROCESS_DONE });
          });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
      });
  }
}
// Create Wallet End //

