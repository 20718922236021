import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import FuseCollapsiblePanel from '../../../components/generic/FuseCollapsiblePanel';
import _ from "lodash";
import { Container, Divider, Button, Icon, Form, Grid, Input, Table, Popup, Confirm, Card, Label, Dimmer, Loader, Modal, TextArea } from "semantic-ui-react";

import * as BASE_QNAIRE_ACTIONS from "../../../actions/tenantPermissionsActions";
import * as TENANT_ACTIONS from '../../../actions/tenantActions';
import * as FINANCES_ACTIONS from '../../../actions/financesActions'

// Conversion functions
const convertPricesToRand = (prices) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (!prices || !prices.prices) return prices;
  return {
    ...prices,
    prices: {
      ...prices.prices,
      qnaireCost: formatter.format(Math.abs(prices.prices.qnaireCost / 100)),
      qnaireCompletionCost: formatter.format(Math.abs(prices.prices.qnaireCompletionCost / 100)),
      appMessaging: formatter.format(Math.abs(prices.prices.appMessaging / 100)),
      whatsAppMessaging: formatter.format(Math.abs(prices.prices.whatsAppMessaging / 100)),
      aiAnalysis: formatter.format(Math.abs(prices.prices.aiAnalysis / 100)),
      subscriptionFee: formatter.format(Math.abs(prices.prices.subscriptionFee / 100)),
      cvSearch: formatter.format(Math.abs(prices.prices.cvSearch / 100)),
      minimumBalance: formatter.format(Math.abs(prices.prices.minimumBalance / 100)),
    },
  };
};

const convertPricesToCents = (prices) => {
  if (!prices || !prices.prices) return prices;
  return {
    ...prices,
    prices: {
      ...prices.prices,
      qnaireCost: prices.prices.qnaireCost * 100,
      qnaireCompletionCost: prices.prices.qnaireCompletionCost * 100,
      appMessaging: prices.prices.appMessaging * 100,
      whatsAppMessaging: prices.prices.whatsAppMessaging * 100,
      aiAnalysis: prices.prices.aiAnalysis * 100,
      subscriptionFee: prices.prices.subscriptionFee * 100,
      cvSearch: prices.prices.cvSearch * 100,
      minimumBalance: prices.prices.minimumBalance * 100,
    },
  };
};

export function ManageTenantsDetails(props) {

  const [state, setState] = useState({
    tenant: {
      ...props.tenant,
      baseQnaires: [],
    },
    tenantPrices: {},
    originalPrices: {},
    isTenantBalancesCollapsed: false,
    isTenantPricingCollapsed: false,
    isQuestionnaireSelectorCollapsed: true,
    loading: false,
    selectedCurrency: null,
    selectedType: null,
  });

  const { handleSubmit } = useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionReason, setReason] = useState('');

  useEffect(() => {
    setState({ ...state, loading: true });
    props.fetchBaseQnaires(props.tenantZero);
    props.fetchGlobalPricesAction();
    props.fetchTenantFinances(props.tenant)
      .then(() => {
        setState({ ...state, loading: false });
        const tenantPricesInRand = convertPricesToRand(props.tenant);
        setState((prevState) => ({
          ...prevState,
          tenantPrices: tenantPricesInRand,
          originalPrices: tenantPricesInRand,
          tenant: {
            ...prevState.tenant,
            ...props.tenant,
          },
        }));
      })
      .catch(() => {
        setState({
          loading: false,
        });
        toast.error('Failed to fetch tenant balances');
        const tenantPricesInRand = convertPricesToRand(props.tenant);
        setState((prevState) => ({
          ...prevState,
          tenantPrices: tenantPricesInRand,
          originalPrices: tenantPricesInRand,
          tenant: {
            ...prevState.tenant,
            ...props.tenant,
          },
        }));
      });
  }, [props.tenantZero]);

  useEffect(() => {
    if (!_.isEmpty(props.tenant.baseQnaires) && props.baseQnaires) {
      const updatedBaseQnaires = props.tenant.baseQnaires.filter((assignedQ) => {
        const correspondingBaseQnaire = props.baseQnaires.find((q) => q.id === assignedQ.id);
        return correspondingBaseQnaire && correspondingBaseQnaire.status !== 'isDeleted';
      });

      setState((prevState) => ({
        ...prevState,
        tenant: {
          ...prevState.tenant,
          baseQnaires: updatedBaseQnaires,
        },
      }));
    }
  }, [props.tenant, props.baseQnaires]);

  const openConfirmModal = () => {
    setState(prevState => ({
      ...prevState,
      showUpdateTenant: true
    }));
  }

  const closeConfirmModal = () => {
    setState(prevState => ({
      ...prevState,
      showUpdateTenant: false
    }));
  }

  const updateTenantDetails = () => {
    return (
      <Confirm
        content={`Are you sure you wish to update the tenant.`}
        open={state.showUpdateTenant}
        onCancel={closeConfirmModal}
        onConfirm={() => {
          const parsedPrices = parsePrices(state.tenantPrices.prices);
          const convertedPrices = convertPricesToCents({ ...state.tenantPrices, prices: parsedPrices });
          props.saveTenant(convertedPrices)

          setState((state) => ({ ...state, }));
          if (_.isEmpty(props.onComplete)) props.onComplete();
          setState((state) => ({ ...state, showUpdateTenant: false }));
        }}
      />
    );
  };

  const parsePrices = (prices) => {
    const parsedPrices = {};
    for (const key in prices) {
      if (prices.hasOwnProperty(key)) {
        const value = prices[key];
        parsedPrices[key] = typeof value === 'string' ? parseFloat(value.replace(",", ".")) : value;
      }
    }
    return parsedPrices;
  };

  const onSubmit = async (data) => {
    openConfirmModal()
  };

  const handleChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      tenantPrices: { ...prevState.tenantPrices, [field]: value },
      isChanged: true,
    }));
  };

  const handleWheel = (e) => {
    e.target.blur();
  };

  const CustomLabel = ({ text, className }) => (
    <label className={`custom-label ${className}`}>
      {text}
    </label>
  );

  const _buildTenantPricing = () => {

    // const [currencyList, setCurrencyList] = useState([]);
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     if (props.tenantFinances.currencies) {
    //     const updatedCurrencyList = Object.entries(props.tenantFinances.currencies).map(([key, value]) => ({
    //         key,
    //         value,
    //         text: key, 
    //     }));
    //     setCurrencyList(updatedCurrencyList);
    //     setLoading(false); 
    //     }
    // }, [props.tenantFinances.currencies]); 

    return (
      <div>
        <h3>Tenant Pricing:</h3>
        {state.loading ? (
          <Dimmer active inverted>
            <Loader>Loading...</Loader>
          </Dimmer>
        ) : (
          <>
            <CustomLabel text="Operating Costs" className="nectaPrimaryBg" />
            <Form.Group widths="equal">
              <Popup
                content="The cost of every questionnaire sent to users."
                trigger={
                  <Form.Field>
                    <label>
                      Questionnaire Delivery Costs (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):
                    </label>
                    <input
                      type="number"
                      name="qnaireCost"
                      placeholder="Questionnaire delivery cost"
                      value={state.tenantPrices.prices?.qnaireCost || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, qnaireCost: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                    />
                  </Form.Field>
                }
              />
              {/* <Popup
            content="The cost of every completed questionnaire sent to users from your dashboard."
            trigger={
              <Form.Field>
                <label>Questionnaire Completion Costs (R):</label>
                <input
                  type="number"
                  name="qnaireCompletionCost"
                  placeholder="Questionnaire completion cost"
                  value={state.tenant.prices?.qnaireCompletionCost || ""}
                  onChange={(e) => handleChange("prices", { ...state.tenant.prices, qnaireCompletionCost: parseInt(e.target.value) || 0  })}
                />
              </Form.Field>
            }
          /> */}
              <Popup
                content="The cost to send a message or questionnaire to the Wakamoso Mobile App."
                trigger={
                  <Form.Field>
                    <label>App Messaging (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):</label>
                    <input
                      type="number"
                      name="appMessaging"
                      placeholder="App messaging"
                      value={state.tenantPrices.prices?.appMessaging || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, appMessaging: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                    />
                  </Form.Field>
                }
              />
              <Popup
                content="The cost to send a message or questionnaire via WhatsApp (User does not have Mobile App installed)"
                trigger={
                  <Form.Field>
                    <label>WhatsApp Messaging (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):</label>
                    <input
                      type="number"
                      name="whatsAppMessaging"
                      placeholder="WhatsApp Messaging"
                      value={state.tenantPrices.prices?.whatsAppMessaging || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, whatsAppMessaging: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                    />
                  </Form.Field>
                }
              />
            </Form.Group>
            <CustomLabel text="Usage Fees" className="nectaPrimaryBg" />
            <Form.Group widths="equal">
              <Popup
                content="The fee to run an AI analysis on a data set within your ecosystem."
                trigger={
                  <Form.Field>
                    <label>AI Analysis (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):</label>
                    <input
                      type="number"
                      name="aiAnalysis"
                      placeholder="AI analysis"
                      value={state.tenantPrices.prices?.aiAnalysis || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, aiAnalysis: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                    />
                  </Form.Field>
                }
              />
              <Popup
                content="The monthly cost per user linked to your tenant dashboard. "
                trigger={
                  <Form.Field>
                    <label>Subscription Fee (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):</label>
                    <input
                      type="number"
                      name="subscriptionFee"
                      placeholder="Subscription Fee"
                      value={state.tenantPrices.prices?.subscriptionFee || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, subscriptionFee: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                    />
                  </Form.Field>
                }
              />
              <Popup
                content="The cost to access the personal information linked to a CV that you wish to access."
                trigger={
                  <Form.Field>
                    <label>CV Purchase Fee (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):</label>
                    <input
                      type="number"
                      name="cvSearch"
                      placeholder="CV Search"
                      value={state.tenantPrices.prices?.cvSearch || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, cvSearch: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                    />
                  </Form.Field>
                }
              />
              <Popup
                content="The % of the CV Purchase Fee paid to the data subject (person whose personal information we share with you)"
                trigger={
                  <Form.Field>
                    <label>CV Owner Fee Split (%):</label>
                    <input
                      type="number"
                      name="cvOwnerSplit"
                      placeholder="CV Owner Split"
                      value={state.tenantPrices.prices?.cvOwnerSplit || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, cvOwnerSplit: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                      min="0"
                      max="100"
                    />
                  </Form.Field>
                }
              />
            </Form.Group>
            <CustomLabel text="Minimum Cash Balance" className="nectaPrimaryBg" />
            <Form.Group widths="equal">
              <Popup
                content="Enter the minimum cash balance required."
                trigger={
                  <Form.Field>
                    <label> Minimum Cash Balance (
                      {props.tenant.operatingCountry?.key || 'ZAR'}
                      ):</label>
                    <input
                      type="text"
                      name="minimumBalance"
                      placeholder="Minimum Cash Balance"
                      value={state.tenantPrices.prices?.minimumBalance || ""}
                      onChange={(e) => handleChange("prices", { ...state.tenantPrices.prices, minimumBalance: e.target.value.replace(/[^0-9.]/g, '') || 0 })}
                      onWheel={handleWheel}
                    />
                  </Form.Field>
                }
              />
            </Form.Group>
          </>
        )}
      </div>
    );
  }

  const handleCurrencyClick = (type, value) => {
    setState({
      ...state,
      selectedType: type === "type" ? value : null,
      selectedCurrency: type === "currency" ? value : null,
      disabled: type === "currency" ? false : true,
    });
  };

  const handleModalOpen = (type) => {
    setModalType(type);
    setModalOpen(true);

  };

  const handleModalClose = () => {
    setModalOpen(false);
    setAmount('');
    setReason('');
    setState({
      ...state,
      selectedType: null,
      selectedCurrency: null,
      disabled: true,
    });
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleAction = () => {
    const currencyData = props.currencies[state.selectedCurrency];
    const nextConversion = currencyData.denominations.find(denom => denom.next_conversion)?.next_conversion || 1;
    const convertedAmount = amount * nextConversion;
    const reason = transactionReason

    if (modalType === 'deposit') {
      props.depositCurrencyAction(props.tenant, state.selectedCurrency, convertedAmount, reason)
      toast.success("Deposit in progress")
      if (_.isEmpty(props.onComplete)) props.onComplete();
    } else if (modalType === 'withdraw') {
      props.withdrawCurrencyAction(props.tenant, state.selectedCurrency, convertedAmount, reason)
      toast.success("Withdrawal in progress")
      if (_.isEmpty(props.onComplete)) props.onComplete();
    }
    handleModalClose();
  };

  const _buildTenantFinances = () => {
    let balances = props.tenantFinances?.currencies;
    let currencies = props.currencies;

    return (
      <div>
        <h3>Balances:</h3>
        {state.loading ? (
          <Dimmer active inverted>
            <Loader>Loading...</Loader>
          </Dimmer>
        ) : !_.isEmpty(balances) ? (
          <>
            <Card.Group>
              {Object.entries(balances).map(([currency, balance]) => {

                const formatter = new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });

                const currencyData = currencies[currency];
                const nextConversion = currencyData.denominations.find(denom => denom.next_conversion)?.next_conversion || 1;
                // const balanceAmount = balance / nextConversion;
                const balanceAmount = formatter.format(Math.abs(balance / nextConversion));


                return (
                  <Card
                    key={currency}
                    onClick={() => handleCurrencyClick("currency", currency)}
                    style={{
                      cursor: 'pointer',
                      border: state.selectedCurrency === currency ? '2px solid #3A95A4' : '2px solid transparent',
                    }}
                  >
                    <Card.Content>
                      <Card.Header>{currency}</Card.Header>
                      <Card.Description>Balance: {balanceAmount}</Card.Description>
                    </Card.Content>
                  </Card>
                );
              })}
            </Card.Group>
            <br />
            <Button.Group floated="left">
              <Button
                type="submit"
                className="success"
                onClick={(e) => {
                  e.preventDefault();
                  handleModalOpen('deposit')
                }}
                disabled={_.isEmpty(state.selectedCurrency)}
              >
                <Icon name="plus" /> Deposit
              </Button>
              <Button
                className="danger"
                onClick={(e) => {
                  e.preventDefault();
                  handleModalOpen('withdraw')
                }}
                disabled={_.isEmpty(state.selectedCurrency)}
              >
                <Icon name="remove" /> Withdraw
              </Button>
            </Button.Group>
            <br />
            <br />
          </>
        ) : (
          <Label>No balances available. Please contact Wakamoso support if this is a mistake.</Label>
        )}
      </div>
    );
  };

  const DisplayBaseQnaires = () => {
    const selectedQuestionnaires = state.tenantPrices.baseQnaires.map((selectedQ) => {
      const baseQnaire = props.baseQnaires.find((q) => q.id === selectedQ.id);
      return {
        ...baseQnaire,
        tenantId: selectedQ.tenantId,
      };
    });

    return (
      <div>
        <br />
        <h3>Selected Questionnaires</h3>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className='nectaPrimaryBg'>Name</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg'>Description</Table.HeaderCell>
              <Table.HeaderCell className="nectaPrimaryBg">Status</Table.HeaderCell>
              <Table.HeaderCell className='nectaPrimaryBg'>Remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedQuestionnaires.map((questionnaire) => (
              <Table.Row key={questionnaire.id}>
                <Table.Cell>{questionnaire.name}</Table.Cell>
                <Table.Cell>{questionnaire.description}</Table.Cell>
                <Table.Cell>{questionnaire.status}</Table.Cell>
                <Table.Cell>
                  <Button
                    compact
                    className="danger"
                    icon="remove"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        tenantPrices: {
                          ...prevState.tenantPrices,
                          baseQnaires: prevState.tenantPrices.baseQnaires.filter(
                            (q) => q.id !== questionnaire.id
                          ),
                        },
                      }));
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  };

  const _buildQuestionnaireSelector = () => {
    const [searchQuery, setSearchQuery] = useState("");

    const availableQuestionnaires = props.baseQnaires
      .filter(
        (questionnaire) =>
          !state.tenantPrices.baseQnaires?.some((selectedQ) => selectedQ.id === questionnaire.id) &&
          questionnaire.status !== 'isDeleted' &&
          (questionnaire.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            questionnaire.description.toLowerCase().includes(searchQuery.toLowerCase()))
      );

    return (
      <Form.Field>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ paddingBottom: "10px" }}>
              <h3>Select Base Questionnaires</h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Input
          icon="search"
          placeholder="Search..."
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
        <Divider />
        {!_.isEmpty(availableQuestionnaires) ? (
          <div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="nectaPrimaryBg">Questionnaire Name</Table.HeaderCell>
                  <Table.HeaderCell className="nectaPrimaryBg">Questionnaire Description</Table.HeaderCell>
                  <Table.HeaderCell className="nectaPrimaryBg">Status</Table.HeaderCell>
                  <Table.HeaderCell className="nectaPrimaryBg" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {availableQuestionnaires.map((questionnaire) => (
                  <Table.Row key={questionnaire.id}>
                    <Table.Cell>{questionnaire.name}</Table.Cell>
                    <Table.Cell>{questionnaire.description}</Table.Cell>
                    <Table.Cell>{questionnaire.status}</Table.Cell>
                    <Table.Cell>
                      <Button
                        compact
                        className="success"
                        icon="checkmark"
                        onClick={(event) => {
                          event.preventDefault();
                          setState((prevState) => ({
                            ...prevState,
                            tenantPrices: {
                              ...prevState.tenantPrices,
                              baseQnaires: [
                                ...prevState.tenantPrices.baseQnaires,
                                questionnaire
                              ],
                            },
                          }));

                          setSearchQuery("");
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <div style={{ padding: "10px", border: "1px solid #ccc", borderRadius: "5px", backgroundColor: "#f8d7da", color: "#721c24" }}>
            All base questionnaires assigned to the tenant
          </div>
        )}
        {state.tenantPrices.baseQnaires && state.tenantPrices.baseQnaires.length > 0 && <DisplayBaseQnaires />}
      </Form.Field>
    );
  };


  return (
    <Container>
      <Form size="small" onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "20px" }}>
        <FuseCollapsiblePanel
          content={
            <>
              {_buildTenantFinances()}

              <Modal open={modalOpen} onClose={handleModalClose}>
                <Modal.Header>{modalType === 'deposit' ? 'Deposit' : 'Withdraw'} </Modal.Header>
                <Modal.Content>
                  <Label>
                    Enter the desired amount of {state.selectedCurrency} you would like to {modalType === 'deposit' ? 'deposit' : 'withdraw'}:
                  </Label>
                  <br />
                  <br />
                  <Input
                    type="number"
                    placeholder="Enter amount"
                    min="1"
                    value={amount}
                    onChange={handleAmountChange}
                    onWheel={handleWheel}
                    style={{ width: '100%' }}
                  />
                  <br />
                  <br />
                  <Label>
                    Reason for {modalType === 'deposit' ? 'deposit' : 'withdrawal'}:
                  </Label>
                  <br />
                  <br />
                  <Input
                    placeholder="Enter a decription..."
                    value={transactionReason}
                    onChange={handleReasonChange}
                    style={{ width: '100%' }}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={handleModalClose}>Cancel</Button>
                  <Button
                    primary
                    onClick={handleAction}
                    disabled={!amount || !transactionReason}
                  >
                    {modalType === 'deposit' ? 'Deposit' : 'Withdraw'}
                  </Button>
                </Modal.Actions>
              </Modal>

            </>
          }
          isCollapsed={state.isTenantBalancesCollapsed}
          leadingIcon="money"
          trailingIcon="angle down"
          header="Tenant Balances"
        />
        <Divider />

        <FuseCollapsiblePanel
          content={
            <>
              {_buildTenantPricing()}
            </>
          }
          isCollapsed={state.isTenantPricingCollapsed}
          leadingIcon="money"
          trailingIcon="angle down"
          header="Tenant Pricing"
        />
        <Divider />
        <FuseCollapsiblePanel
          content={
            <>
              {_buildQuestionnaireSelector()}
            </>
          }
          isCollapsed={state.isQuestionnaireSelectorCollapsed}
          leadingIcon="question"
          trailingIcon="angle down"
          header="Base Questionnaire Selector"
        />
        <Divider />
        <Button.Group floated="right">
          <Button
            className="danger"
            onClick={() => {
              if (_.isEmpty(props.onComplete)) {
                props.onComplete();
              }
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button type="submit" className="success">
            <Icon name="checkmark" /> Ok
          </Button>
        </Button.Group>
      </Form>
      {updateTenantDetails()}
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenantZero: state.tenantManagement.activeTenant,
    baseQnaires: state.tenZeroBaseQuestionnaires,
    tenantFinances: state.tenantFinances.tenantBalances,
    // tenantTransactions: state.tenantFinances.tenantTransactions,
    currencies: state.tenantFinances.currencies,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchBaseQnaires: (tenant) => dispatch(BASE_QNAIRE_ACTIONS.fetchTenZeroBaseQnairesAction(tenant)),
    fetchTenantFinances: (tenant) => dispatch(FINANCES_ACTIONS.fetchTenantFinances(tenant)),
    fetchGlobalPricesAction: () => dispatch(FINANCES_ACTIONS.fetchGlobalPricesAction()),
    depositCurrencyAction: (tenant, currency, amount, reason) => dispatch(FINANCES_ACTIONS.depositCurrencyAction(tenant, currency, amount, reason)),
    withdrawCurrencyAction: (tenant, currency, amount, reason) => dispatch(FINANCES_ACTIONS.withdrawCurrencyAction(tenant, currency, amount, reason)),
    saveTenant: (tenant) => dispatch(TENANT_ACTIONS.saveTenantAction(tenant)),
  })
)(ManageTenantsDetails);
