export class QuestionaireDTO {
    GSI2PK;
    GSI2SK;
    GSI3PK;
    GSI3SK;
    PK;
    SK;
    id;
    name;
    description;
    multiple;
    defaultQuestionnaire;
    isActive;
    isBaseQnaire;
    hasPptTemplates;
    pptRef;
    type;
    questionaireType;
    status;
    createdAt;
    updatedAt;
    countryCode;
    activationPhrase;
    // whatsappAvailable;
    tenantId;
    canEditQnaire;
    anonymisedData;
    qnaireReward;
    qnaireRewardCurrency;
    alwaysAnonymise;

    constructor(data) {
      this.GSI2PK = `${data.tenantId}#QNAIRES`;
      this.GSI3PK = "QNAIRES";
      this.GSI3SK = data.defaultQuestionnaire ? "QNAIRE#DEFAULT" : "QNAIRE#NOTDEFAULT";
      if (data.id != null) {
        this.GSI2SK = data.GSI2SK;
        this.PK = data.PK;
        this.SK = data.SK;
      }
      else {
        this.GSI2SK = "QNAIRE#";
        this.PK = `${data.tenantId}#QNAIRE#`;
        this.SK = "QNAIRE#";
      }
      this.id = data.id;
      this.name = data.name;
      this.description = data.description;
      this.multiple = data.multiple;
      this.defaultQuestionnaire = (data.defaultQuestionnaire === null || data.defaultQuestionnaire === undefined) ? false : data.defaultQuestionnaire;
      this.isActive = data.isActive;
      this.isBaseQnaire = data.isBaseQnaire;
      this.pptRef = data.pptRef;
      this.hasPptTemplates = data.hasPptTemplates;
      this.type = "QNAIRE";
      this.questionaireType = data.questionaireType;
      this.status = data.status;
      this.countryCode = data.countryCode;
      this.activationPhrase = data.activationPhrase;
      // this.whatsappAvailable=data.whatsappAvailable;
      this.canEditQnaire = data.canEditQnaire;
      this.tenantId = data.tenantId;
      this.anonymisedData = data.anonymisedData;
      this.qnaireReward = data.qnaireReward;
      this.alwaysAnonymise = data.alwaysAnonymise;
      this.qnaireRewardCurrency = data.qnaireRewardCurrency;
    }
  }