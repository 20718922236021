import { toast } from 'react-toastify';
import { SAVE_IN_PROGRESS, SAVE_PROCESS_DONE } from "./fetchingDataActions";
import {QuestionaireDTO} from './DTOs/QuestionaireDTO';
import {QuestionDTO} from './DTOs/QuestionDTO';
import {SectionDTO} from './DTOs/SectionDTO';
import { fetchItemsWS } from '../webservices/fetchWS';
import { transactItemWs, updateItemWs } from '../webservices/saveWS';
import { approveQnaireNotifWs } from '../webservices/reviewWS';
import { getTenantFinances } from '../webservices/walletWS';

export const FETCHING_QUESTIONAIRES_ACTION = 'FETCHING_QUESTIONAIRES_ACTION';
export const FETCH_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_QUESTIONAIRES_ERROR_ACTION = 'FETCH_QUESTIONAIRES_ERROR_ACTION';

export const FETCH_QUESTIONS_ERROR_ACTION = 'FETCH_QUESTIONS_ERROR_ACTION';

export const QUESTIONAIRE_ADD_ACTION = 'QUESTIONAIRE_ADD_ACTION';
export const QUESTIONAIRE_EDIT_ACTION = 'QUESTIONAIRE_EDIT_ACTION';
export const QUESTIONAIRE_DELETE_ACTION = 'QUESTIONAIRE_DELETE_ACTION';

export const QUESTION_DELETE_ACTION = 'QUESTION_DELETE_ACTION';
export const SECTION_DELETE_ACTION = 'SECTION_DELETE_ACTION';

export const fetchQuestionairesAction = (tenantId) => {
  const queryParams = {
    GSI2PK: `${tenantId}%23QNAIRES`,
    GSI2SK: 'QNAIRE',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_QUESTIONAIRES_ACTION, payload: tenantId });

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.status !== 'isDeleted');
      const state = getState();
      const tenant = state.tenantManagement.activeTenant

      if(tenant.baseQnaires === undefined || tenant.baseQnaires.length === 0){
        dispatch({ type: FETCH_QUESTIONAIRES_SUCCESS_ACTION, payload: returnItems });
      }
      else{
        const matchingBaseQnaires = await dispatch(fetchBaseQuestionairesAction(tenant));
        const combinedResults = [...returnItems, ...matchingBaseQnaires];
        dispatch({ type: FETCH_QUESTIONAIRES_SUCCESS_ACTION, payload: combinedResults });
      }
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_QUESTIONAIRES_ERROR_ACTION, err });
    }
  };
};


//Creating and updating of questionnaires
export const questionaireSaveAction = (questionaire, isNewItem) => {
  return (dispatch, getState) => {
    questionaire.status = questionaire.isActive?"underReview":"inActive"
    questionaire.isActive = false
    const saveObject = new QuestionaireDTO(questionaire);
    if(questionaire.status === "underReview"){
      approveQnaireNotifWs(saveObject, getState)
    }
    dispatch({ type: SAVE_IN_PROGRESS });
    if (isNewItem === true) {
      transactItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: QUESTIONAIRE_ADD_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The new questionnaire has been added successfully');
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to add the new questionnaire please try again');
        })
    }
    else {
      updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: QUESTIONAIRE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.success('The questionnaire has been updated successfully');
          const state = getState();
          const tenant = state.tenantManagement.activeTenant
          dispatch(fetchQuestionairesAction(tenant.tenantId));

        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
          toast.error('Failed to update the questionnaire please try again');
        })
    }
  }
}

//Creating and updating of questionnaires
export const deactiveQuestionaireAction = (questionaire) => {
  return (dispatch, getState) => {
    questionaire.status = "inActive"
    questionaire.isActive = false
    const saveObject = new QuestionaireDTO(questionaire);
    dispatch({ type: SAVE_IN_PROGRESS });
    updateItemWs(saveObject, getState)
        .then((result) => {
          dispatch({ type: QUESTIONAIRE_EDIT_ACTION, payload: result.data });
          dispatch({ type: SAVE_PROCESS_DONE });
          const state = getState();
          const tenant = state.tenantManagement.activeTenant
          dispatch(fetchQuestionairesAction(tenant.tenantId));

        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: SAVE_PROCESS_DONE });
        })
  }
}

//Deleting a questionnaire and its questions.
export const questionaireDeleteAction = (questionaire) => {
  return (dispatch, getState) => {
    const saveObject = new QuestionaireDTO(questionaire);
    saveObject.status = 'isDeleted';

    // Update the questionnaire status
    updateItemWs(saveObject,  getState)
      .then((result) => {
        dispatch({ type: QUESTIONAIRE_DELETE_ACTION, payload: result.data.id });

        let queryParams1 = {
          PK: `${questionaire.tenantId}%23QNAIRE%23${questionaire.id}`,
          SK: 'SECTION%23',
        };

        // Fetch all the questions for the deleted questionnaire
        fetchItemsWS('get-dynamodb', queryParams1, getState)
          .then((result) => {
            const sections = result.Items;

            // Update the status of each question to "isDeleted"
            sections.forEach((section) => {
              const saveSection = new SectionDTO(section);
              saveSection.status = 'isDeleted';

              updateItemWs(saveSection,  getState)
                .then((result) => {
                  dispatch({ type: SECTION_DELETE_ACTION, payload: result.data.id });
                })
                .catch((err) => {
                  console.log(err);
                  dispatch({ type: SAVE_PROCESS_DONE });
                  toast.error('Failed to delete a question. Please try again.');
                });
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_QUESTIONS_ERROR_ACTION, err });
          });

        let queryParams2 = {
          PK: `${questionaire.tenantId}%23QNAIRE%23${questionaire.id}`,
          SK: 'QUESTION%23',
        };

        // Fetch all the questions for the deleted questionnaire
        fetchItemsWS('get-dynamodb', queryParams2, getState)
          .then((result) => {
            const questions = result.Items;

            // Update the status of each question to "isDeleted"
            questions.forEach((question) => {
              const saveQuestion = new QuestionDTO(question);
              saveQuestion.status = 'isDeleted';

              updateItemWs(saveQuestion,  getState)
                .then((result) => {
                  dispatch({ type: QUESTION_DELETE_ACTION, payload: result.data.id });
                })
                .catch((err) => {
                  console.log(err);
                  dispatch({ type: SAVE_PROCESS_DONE });
                  toast.error('Failed to delete a question. Please try again.');
                });
            });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: FETCH_QUESTIONS_ERROR_ACTION, err });
          });

        dispatch({ type: SAVE_PROCESS_DONE });
        toast.success('The questionnaire and its questions have been deleted successfully');
        const state = getState();
        const tenant = state.tenantManagement.activeTenant
        dispatch(fetchQuestionairesAction(tenant.tenantId));
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: SAVE_PROCESS_DONE });
        toast.error('Failed to delete the questionnaire. Please try again');
      });
  };
};


//Fetch Base Questionnaires 
export const FETCHING_BASE_QUESTIONAIRES_ACTION = 'FETCHING_BASE_QUESTIONAIRES_ACTION';
export const FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_BASE_QUESTIONAIRES_ERROR_ACTION = 'FETCH_BASE_QUESTIONAIRES_ERROR_ACTION';

export const fetchBaseQuestionairesAction = (tenant) => {
  const queryParams = {
    GSI2PK: `2WWjarbwyZ6IvJpoQKfzVWkGWiK%23QNAIRES`,
    GSI2SK: 'QNAIRE%23',
  };

  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_BASE_QUESTIONAIRES_ACTION, payload: tenant });

    try {
      const result = await fetchItemsWS('get-dynamodb', queryParams, getState);
      const returnItems = result.Items.filter(item => item.isBaseQnaire === true && item.status !== "isDeleted");

      // Merge tenant's base questionnaires with fetched base questionnaires from tenant zero
      const matchingBaseQnaires = tenant.baseQnaires.map(tenantBaseQnaire => {
      const matchingFetchedQnaire = returnItems.find(item => item.id === tenantBaseQnaire.id);

      // Check if the fetched questionnaire with the same ID exists
      if (matchingFetchedQnaire && matchingFetchedQnaire.isBaseQnaire && matchingFetchedQnaire.status !== "isDeleted") {
        // If it exists, merge the properties and return the merged object
        return { ...tenantBaseQnaire, ...matchingFetchedQnaire };
      }

      // If it doesn't exist or is not a base questionnaire, return the original tenant base questionnaire
      return tenantBaseQnaire;
    });

      dispatch({ type: FETCH_BASE_QUESTIONAIRES_SUCCESS_ACTION, payload: matchingBaseQnaires });

      return matchingBaseQnaires;
    } catch (err) {
      console.log(err);
      dispatch({ type: FETCH_BASE_QUESTIONAIRES_ERROR_ACTION, err });
      throw err;
    }
  };
};


export const FETCHING_ALL_QUESTIONAIRES_ACTION = 'FETCHING_ALL_QUESTIONAIRES_ACTION';
export const FETCH_ALL_QUESTIONAIRES_SUCCESS_ACTION = 'FETCH_ALL_QUESTIONAIRES_SUCCESS_ACTION';
export const FETCH_ALL_QUESTIONAIRES_ERROR_ACTION = 'FETCH_ALL_QUESTIONAIRES_ERROR_ACTION';

export const fetchAllQnairesAction = () => {
  const queryParams = {
    GSI3PK: `QNAIRES`,
    GSI3SK: 'QNAIRE%23DEFAULT',
  };

  return (dispatch, getState) => {
    dispatch({ type: FETCHING_ALL_QUESTIONAIRES_ACTION});
    fetchItemsWS('get-dynamodb', queryParams, getState)
      .then((result) => {
        const returnItems = result.Items.filter(item => item.status !== "isDeleted");
        dispatch({type: FETCH_ALL_QUESTIONAIRES_SUCCESS_ACTION,payload: returnItems,});
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: FETCH_ALL_QUESTIONAIRES_ERROR_ACTION, err });
      });
  };
}

export const FETCHING_TENANT_FINANCES_ACTION = "FETCHING_TENANT_FINANCES_ACTION";
export const FETCH_TENANT_FINANCES_SUCCESS_ACTION = "FETCH_TENANT_FINANCES_SUCCESS_ACTION";
export const FETCH_TENANT_FINANCES_ERROR_ACTION = "FETCH_TENANT_FINANCES_ERROR_ACTION";

export const fetchTenantBalances = (tenant) => {
    const queryParams = {
        userId: tenant.walletUserId,
        walletId: tenant.walletId,
    };

    return async (dispatch, getState) => {
        dispatch({ type: FETCHING_TENANT_FINANCES_ACTION });
        try {
            const financesResult = await getTenantFinances(queryParams, getState);
            const returnItems = financesResult.data;
            dispatch({ type: FETCH_TENANT_FINANCES_SUCCESS_ACTION, payload: returnItems });
           
        } catch (err) {
            dispatch({ type: FETCH_TENANT_FINANCES_ERROR_ACTION, err });
        }
    };
};