import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { toast } from 'react-toastify';
import { Container, Form, Button, Icon, Segment, Confirm, TextArea, Input, Header, Dropdown } from "semantic-ui-react";

import * as TENANT_ACTIONS from '../../../actions/tenantActions';
import * as FINANCES_ACTIONS from '../../../actions/financesActions'

export function ManageTenantPage(props) {
    const [state, setState] = useState({
        tenant: { ...props.tenant },
        originalTenant: { ...props.tenant },
        isChanged: false,
        isEditing: false,
        showConfirmDeleteTenant: false,
        denied: false,
    });

    const hasPermissions = (category) => {
        return !_.isEmpty(props.userPermissions) && props.userPermissions.hasOwnProperty(category);
    };

    const isAdmin = () => {
        return hasPermissions('Other') && props.userPermissions['Other'].actions.admin;
    };

    const canReadTenant = () => {
        return hasPermissions('Manage Tenant') && props.userPermissions['Manage Tenant'].actions.READ || isAdmin();
    };

    const canEditTenant = () => {
        return hasPermissions('Manage Tenant') && props.userPermissions['Manage Tenant'].actions.UPDATE || isAdmin();
    };

    useEffect(() => {
        if (!_.isEmpty(props.tenant)) {
            if (props.isPermissionsFetched) {
                if (canReadTenant()) {
                    setState({
                        denied: false,
                    })
                }
                else {
                    setState({
                        denied: true,
                    })
                    toast.error('You do not have permission to view the tenant information');
                }
            }
        }
    }, [props.tenant]);

    useEffect(() => {
        if (!state.isEditing) {
            setState({
                tenant: { ...props.tenant },
                originalTenant: { ...props.tenant },
                isChanged: false,
            });
        }
    }, [props.tenant, state.isEditing]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = async (data) => {

        if (
            _.isEmpty(state.tenant.contactDetails) ||
            _.isEmpty(state.tenant.address)
        ) {
            toast.error("Please fill in all the contact and address details!");
        } else if (
            _.isEmpty(state.tenant.operatingCountry)
        ) {
            toast.error("Please select an operating country!");
        }
        else {
            await props.saveTenant(state.tenant);

            if (_.isFunction(props.onComplete)) {
                props.onComplete();
            }

            toggleEdit();
        }
    };

    const onCancel = () => {
        setState((prevState) => ({
            ...prevState,
            tenant: { ...prevState.originalTenant },
            isChanged: false,
            isEditing: false,
        }));
    };

    const toggleEdit = () => {
        setState((prevState) => ({
            ...prevState,
            isEditing: !prevState.isEditing,
        }));
    };

    const handleChange = (field, value) => {
        setState((prevState) => ({
            ...prevState,
            tenant: { ...prevState.tenant, [field]: value },
            isChanged: true,
        }));
    };

    const confirmDeleteTenant = () => {
        return (
            <Confirm
                content={`Warning: Deleting this tenant will permanently remove all associated data.  If you wish to proceed, select "OK".  If you wish to abandon this process, select "Cancel".`}
                open={state.showConfirmDeleteTenant}
                onCancel={() => setState((state) => ({ ...state, showConfirmDeleteTenant: false }))}
                onConfirm={(e) => {
                    e.stopPropagation();
                    props.deleteTenantAction(props.tenant, props.sourceTenant);
                    setState((state) => ({ ...state, showConfirmDeleteTenant: false, deleteActRuleCandidate: {} }));
                }}
            />
        );
    };

    const _buildName = () => {
        return (
            <Form.Field>
                <label>Name:</label>
                <Input
                    name="companyName"
                    className={errors.name ? "invalid-input-field" : ""}
                    {...register("name", { maxLength: 128 })}
                    placeholder="Name"
                    value={state.tenant.companyName || state.tenant.name || ""}
                    onChange={(e) => handleChange("companyName", e.target.value)}
                    readOnly={!state.isEditing}

                />
                {errors.name && errors.name.type === "required" && (
                    <p className="validation-error">* Please enter a name</p>
                )}
                {errors.name && errors.name.type === "maxLength" && (
                    <p className="validation-error">
                        * Name may not be more than 128 characters long
                    </p>
                )}
            </Form.Field>
        );
    };

    const _buildDescription = () => {
        return (
            <Form.Field>
                <label>Company Synopsis:</label>
                <TextArea
                    name="description"
                    className={errors.description ? "invalid-input-field" : ""}
                    {...register("description", { maxLength: 500 })}
                    placeholder="Description"
                    value={state.tenant.description}
                    onChange={(e) => handleChange("description", e.target.value)}
                    readOnly={!state.isEditing}
                    style={{ color: "DarkGray" }}
                />
                {errors.description && errors.description.type === "maxLength" && (
                    <p className="validation-error">
                        * Description may not be more than 500 characters long
                    </p>
                )}
            </Form.Field>
        );
    };

    const _buildWebURL = () => {
        return (
            <Form.Field>
                <label>Company Website:</label>
                <Input
                    name="weburl"
                    className={errors.description ? "invalid-input-field" : ""}
                    {...register("weburl", { maxLength: 255 })}
                    placeholder="mysite.com"
                    value={state.tenant.weburl}
                    onChange={(e) => handleChange("weburl", e.target.value)}
                    readOnly={!state.isEditing}
                />
            </Form.Field>
        );
    };

    const _buildOperatingCountry = () => {
        const countryOptions = [
            { key: 'ZAR', value: 'south_africa', text: 'South Africa', code: '+27' },
            { key: 'MZN', value: 'mozambique', text: 'Mozambique', code: '+258' } // Uncomment when we are ready for moz tenants.
        ];

        // If operatingCountry is empty, show dropdown to select a country
        if (_.isEmpty(state.tenant.operatingCountry)) {
            return (
                <Form.Field>
                    <label>Operating Country:</label>
                    <Dropdown
                        name="operatingCountry"
                        placeholder="Select Operating Country"
                        options={countryOptions}
                        value={state.tenant.operatingCountry ? state.tenant.operatingCountry.value : null}
                        onChange={(e, { value }) => {
                            const selectedCountry = countryOptions.find(country => country.value === value);
                            handleChange("operatingCountry", selectedCountry);
                        }}
                        selection
                        disabled={!state.isEditing}
                    />
                </Form.Field>
            );
        } else {
            return (
                <Form.Field>
                    <label>Operating Country:</label>
                    <Input
                        name="operatingCountry"
                        value={state.tenant.operatingCountry.text}
                        readOnly
                    />
                </Form.Field>
            );
        }
    };

    const _buildGeneralDetailsGroup = () => {
        return (
            <>
                {_buildName()}
                {_buildDescription()}
                <Form.Group widths="equal">
                    {_buildOperatingCountry()}
                    {_buildWebURL()}
                </Form.Group>
            </>
        );
    };

    const _buildAddressGroup = () => {
        return (
            <>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Street:</label>
                        <Input
                            name="street"
                            className={errors.street ? "invalid-input-field" : ""}
                            {...register("street")}
                            placeholder="Street"
                            value={state.tenant.address?.street || ""}
                            onChange={(e) => handleChange("address", { ...state.tenant.address, street: e.target.value })}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>City:</label>
                        <Input
                            name="city"
                            className={errors.city ? "invalid-input-field" : ""}
                            {...register("city")}
                            placeholder="City"
                            value={state.tenant.address?.city || ""}
                            onChange={(e) => handleChange("address", { ...state.tenant.address, city: e.target.value })}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Province:</label>
                        <Input
                            name="province"
                            className={errors.state ? "invalid-input-field" : ""}
                            {...register("province")}
                            placeholder="Province"
                            value={state.tenant.address?.province || ""}
                            onChange={(e) => handleChange("address", { ...state.tenant.address, province: e.target.value })}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Postal Code:</label>
                        <Input
                            name="postalCode"
                            className={errors.postalCode ? "invalid-input-field" : ""}
                            {...register("postalCode")}
                            placeholder="Postal Code"
                            value={state.tenant.address?.postalCode || ""}
                            onChange={(e) => handleChange("address", { ...state.tenant.address, postalCode: e.target.value })}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Country:</label>
                        <Input
                            name="country"
                            className={errors.country ? "invalid-input-field" : ""}
                            {...register("country")}
                            placeholder="Country"
                            value={state.tenant.address?.country || ""}
                            onChange={(e) => handleChange("address", { ...state.tenant.address, country: e.target.value })}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    };

    const _buildContactDetailsGroup = () => {
        return (
            <>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Cellphone Number:</label>
                        <Input
                            name="cellphone"
                            className={errors.cellphone ? "invalid-input-field" : ""}
                            {...register("cellphone")}
                            placeholder="Cellphone Number"
                            value={state.tenant.contactDetails?.cellphone || ""}
                            onChange={(e) => handleChange("contactDetails", { ...state.tenant.contactDetails, cellphone: e.target.value })}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Email Address:</label>
                        <Input
                            name="email"
                            className={errors.email ? "invalid-input-field" : ""}
                            {...register("email")}
                            placeholder="Email Address"
                            value={state.tenant.contactDetails?.email || ""}
                            onChange={(e) => handleChange("contactDetails", { ...state.tenant.contactDetails, email: e.target.value })}
                            readOnly={!state.isEditing}
                        />
                    </Form.Field>
                </Form.Group>
            </>
        );
    };

    const _buildOtherDetails = () => {
        return (
            <Form.Group widths="equal">
                <Form.Field>
                    <label>Company VAT Number:</label>
                    <Input
                        name="vatNumber"
                        className={errors.vatNumber ? "invalid-input-field" : ""}
                        {...register("vatNumber")}
                        placeholder="Company VAT Number"
                        value={state.tenant.vatNumber || ""}
                        onChange={(e) => handleChange("vatNumber", e.target.value)}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        readOnly={!state.isEditing}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Purchase Order Number:</label>
                    <Input
                        name="poNumber"
                        className={errors.poNumber ? "invalid-input-field" : ""}
                        {...register("poNumber")}
                        placeholder="Company Purchase Order Number"
                        value={state.tenant.poNumber || ""}
                        onChange={(e) => handleChange("poNumber", e.target.value)}
                        onInput={(e) => {
                            e.target.value = e.target.value;
                        }}
                        readOnly={!state.isEditing}
                    />
                </Form.Field>
            </Form.Group>
        );
    };

    const CustomLabel = ({ text, className }) => (
        <label className={`custom-label ${className}`}>
            {text}
        </label>
    );

    const deleteTenant = async (e, tenant) => {
        e.preventDefault();
        e.stopPropagation();

        // Fetch tenant finances
        const balances = await props.fetchTenantFinances(props.tenant);

        // Check if there are any balances remaining for the tenant
        const currencies = balances.currencies;

        // Check if any currency balance is greater than zero
        const hasBalances = Object.values(currencies).some(balance => balance > 0);

        if (hasBalances) {
            toast.error("Please Make sure all the associated balances for this tenant are empty before deleting!");
        } else {
            setState((prevState) => ({ ...prevState, showConfirmDeleteTenant: true }));
        }
    };

    if (state.denied) {
        return (
            <Container>
                <Segment>
                    <Header icon>
                        <Icon name='ban' color="red" />
                        You do not have permissions to view tenant details
                    </Header>
                </Segment>
            </Container>
        );
    }

    return (
        <Container fluid>
            {confirmDeleteTenant()}
            <h3>Manage Tenant:</h3>
            <Segment raised>
                <Form
                    size="medium"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ paddingBottom: "25px" }}
                >
                    <CustomLabel text="General Details" className="nectaPrimaryBg" />
                    {_buildGeneralDetailsGroup()}

                    <CustomLabel text="Address Details" className="nectaPrimaryBg" />
                    {_buildAddressGroup()}

                    <CustomLabel text="Contact Details" className="nectaPrimaryBg" />
                    {_buildContactDetailsGroup()}

                    <CustomLabel text="Other Details" className="nectaPrimaryBg" />
                    {_buildOtherDetails()}

                    <CustomLabel className="nectaPrimaryBg" />
                    {canEditTenant() && (
                        <Button.Group floated="right">
                            {!state.isEditing && (
                                <Button.Group floated="right">
                                    <Button className="warning" onClick={toggleEdit}>
                                        <Icon name="pencil" /> Edit
                                    </Button>
                                    {props.tenant.isRoot !== true && (
                                        <Button className="danger" onClick={(e) => deleteTenant(e, props.tenant)}>
                                            <Icon name="trash" /> Delete Tenant
                                        </Button>
                                    )}
                                </Button.Group>
                            )}
                            {state.isEditing && (
                                <>
                                    <Button type="button" className="danger" onClick={onCancel}>
                                        <Icon name="cancel" /> Cancel
                                    </Button>
                                    <Button type="submit" className="success" >
                                        <Icon name="checkmark" /> Ok
                                    </Button>
                                </>
                            )}
                        </Button.Group>
                    )}
                </Form>
            </Segment>
        </Container>
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        tenant: state.tenantManagement.activeTenant,
        sourceTenant: state.tenantManagement.sourceTenant,
        userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
        isPermissionsFetched: state.isPermissionsFetched === true,
        tenantFinances: state.tenantFinances.tenantFinances,
    };
};

export default connect(
    // map state to props
    mapStateToProps,
    // map dispatch to props
    (dispatch) => ({
        fetchTenantFinances: (tenant) => dispatch(FINANCES_ACTIONS.fetchTenantCurrentBalances(tenant)),
        saveTenant: (tenant) => dispatch(TENANT_ACTIONS.saveTenantAction(tenant)),
        deleteTenantAction: (tenant, sourceTenant) => dispatch(TENANT_ACTIONS.deleteTenantAction(tenant, sourceTenant)),
    })
)(ManageTenantPage);
