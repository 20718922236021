export class TenantDTO {
    PK;
    SK;
    createdAt;
    updatedAt;
    description;
    name;
    type;
    id;
    address;
    contactDetails;
    vatNumber;
    poNumber;
    companyName;
    baseQnaires;
    status;
    prices;
    walletId;
    walletUserId;
    weburl;
    subAgreement;
    subAgreeDate;
    operatingCountry;


    constructor(tenant) {
        if (tenant.id != null) {
            this.PK = tenant.PK;
            this.SK = tenant.SK;
        }
        else {
            this.PK = "TENANTS";
            this.SK = `TENANT#${tenant.tenantName}`;
        }
        this.description = tenant.description || tenant.tenantDescription;
        this.name = tenant.tenantName;
        this.id = tenant.id;
        this.type = "TENANT";
        this.address = tenant.address;
        this.contactDetails = tenant.contactDetails;
        this.vatNumber = tenant.vatNumber;
        this.poNumber = tenant.poNumber;
        this.companyName = tenant.companyName || this.name;
        this.status = tenant.status || "active";
        this.baseQnaires = tenant.baseQnaires;
        this.prices = tenant.prices;
        this.walletId = tenant.walletId;
        this.walletUserId = tenant.walletUserId;
        this.weburl = tenant.weburl;
        this.subAgreement = tenant.subAgreement;
        this.subAgreeDate = tenant.subAgreeDate;
        this.operatingCountry = tenant.operatingCountry;
    }
}