import { SAVE_PROCESS_DONE } from "./fetchingDataActions";
import { toast } from "react-toastify";
import { getTenantFinances, getTenantTransactions, getCurrencies, depositCurrency, withdrawCurrency } from "../webservices/walletWS";
import { fetchItemsWS } from "../webservices/fetchWS";

export const FETCHING_CURRENCY_ACTION = "FETCHING_CURRENCY_ACTION";
export const FETCH_CURRENCY_SUCCESS_ACTION = "FETCH_CURRENCY_SUCCESS_ACTION";
export const FETCH_CURRENCY_ERROR_ACTION = "FETCH_CURRENCY_ERROR_ACTION";

export const fetchTenantFinances = (tenant) => {
    return async (dispatch, getState) => {
        dispatch({ type: FETCHING_TENANT_FINANCES_ACTION });
        try {
            const currenciesResult = await getCurrencies(getState);
            const returnItems = currenciesResult.data;
            dispatch({ type: FETCH_CURRENCY_SUCCESS_ACTION, payload: returnItems });
            await dispatch(fetchTenantBalances(tenant));
        } catch (err) {
            dispatch({ type: FETCH_CURRENCY_ERROR_ACTION, err });
        }
    };
};

export const FETCHING_TENANT_FINANCES_ACTION = "FETCHING_TENANT_FINANCES_ACTION";
export const FETCH_TENANT_FINANCES_SUCCESS_ACTION = "FETCH_TENANT_FINANCES_SUCCESS_ACTION";
export const FETCH_TENANT_FINANCES_ERROR_ACTION = "FETCH_TENANT_FINANCES_ERROR_ACTION";

export const fetchTenantBalances = (tenant) => {
    const queryParams = {
        userId: tenant.walletUserId,
        walletId: tenant.walletId,
    };

    return async (dispatch, getState) => {
        dispatch({ type: FETCHING_TENANT_FINANCES_ACTION });
        try {
            const financesResult = await getTenantFinances(queryParams, getState);
            const returnItems = financesResult.data;
            dispatch({ type: FETCH_TENANT_FINANCES_SUCCESS_ACTION, payload: returnItems });
            await dispatch(fetchTenantTransactions(tenant));
        } catch (err) {
            dispatch({ type: FETCH_TENANT_FINANCES_ERROR_ACTION, err });
        }
    };
};

export const FETCHING_TENANT_TRANSACTIONS_ACTION = "FETCHING_TENANT_TRANSACTIONS_ACTION";
export const FETCH_TENANT_TRANSACTIONS_SUCCESS_ACTION = "FETCH_TENANT_TRANSACTIONS_SUCCESS_ACTION";
export const FETCH_TENANT_TRANSACTIONS_ERROR_ACTION = "FETCH_TENANT_TRANSACTIONS_ERROR_ACTION";

export const fetchTenantTransactions = (tenant) => {
    const queryParams = {
        walletId: tenant.walletId,
    };

    return async (dispatch, getState) => {
        dispatch({ type: FETCHING_TENANT_TRANSACTIONS_ACTION });
        try {
            const transactionsResult = await getTenantTransactions(queryParams, getState);
            const returnItems = transactionsResult.data;
            dispatch({ type: FETCH_TENANT_TRANSACTIONS_SUCCESS_ACTION, payload: returnItems });
        } catch (err) {
            dispatch({ type: FETCH_TENANT_TRANSACTIONS_ERROR_ACTION, err });
        }
    };
};

export const fetchFilteredTenantTransactions = (tenant, filter) => {
    const queryParams = {
        walletId: tenant.walletId,
        filterType: filter?.filterType,
        filter: filter?.filter,
    };

    return async (dispatch, getState) => {
        dispatch({ type: FETCHING_TENANT_TRANSACTIONS_ACTION });
        try {
            const transactionsResult = await getTenantTransactions(queryParams, getState);
            const returnItems = transactionsResult.data;
            dispatch({ type: FETCH_TENANT_TRANSACTIONS_SUCCESS_ACTION, payload: returnItems });
        } catch (err) {
            dispatch({ type: FETCH_TENANT_TRANSACTIONS_ERROR_ACTION, err });
        }
    };
}


export const fetchTenantCurrentBalances = (tenant) => {
    const queryParams = {
        userId: tenant.walletUserId,
        walletId: tenant.walletId,
    };

    return async (dispatch, getState) => {
        try {
            const result = await getTenantFinances(queryParams, getState);
            const returnItems = result.data;
            return returnItems;
        } catch (err) {
            throw err;
        }
    };
};

export const FETCHING_GLOBALPRICES_ACTION = "FETCHING_GLOBALPRICES_ACTION";
export const FETCH_GLOBALPRICES_SUCCESS_ACTION = "FETCH_GLOBALPRICES_SUCCESS_ACTION";
export const FETCH_GLOBALPRICES_ERROR_ACTION = "FETCH_GLOBALPRICES_ERROR_ACTION";

export const fetchGlobalPricesAction = () => {
    const queryParams = {
        PK: `GLOBALPRICES`,
        SK: 'GLOBALPRICES',
    };

    return (dispatch, getState) => {
        dispatch({ type: FETCHING_GLOBALPRICES_ACTION });
        fetchItemsWS('get-dynamodb', queryParams, getState)
            .then((result) => {
                const returnItems = result.Items.filter(item => item.status !== "isDeleted");
                dispatch({ type: FETCH_GLOBALPRICES_SUCCESS_ACTION, payload: returnItems });
            })
            .catch((err) => {
                console.log(err);
                dispatch({ type: FETCH_GLOBALPRICES_ERROR_ACTION, err });
            });
    };
};

export const depositCurrencyAction = (tenant, currency, amount, reason) => {
    return async (dispatch, getState) => {
        let payload = {
            userId: tenant.walletUserId,
            walletId: tenant.walletId,
            currency: currency,
            amount: amount,
            description: reason,
            source: 'Account topup via Web Portal',
        }
        try {
            await depositCurrency(payload, getState);
            toast.success(`Deposit Successful`);
            return fetchTenantFinances(tenant)(dispatch, getState);
        } catch (err) {
            console.log(err);
            toast.error("Despoit unsuccessful. Please try again later.");
            dispatch({ type: SAVE_PROCESS_DONE });
        }
    }
};

export const withdrawCurrencyAction = (tenant, currency, amount, reason) => {
    return async (dispatch, getState) => {
        let payload = {
            userId: tenant.walletUserId,
            walletId: tenant.walletId,
            currency: currency,
            amount: amount,
            description: reason,
            target: 'Account withdrawel via Web Portal'
        }
        try {
            const result = await withdrawCurrency(payload, getState);
            toast.success("Withdrawal Successful");
            return fetchTenantFinances(tenant)(dispatch, getState);
        } catch (err) {
            console.log(err);
            toast.error("Withdrawal unsuccessful. Please try again later.");
            dispatch({ type: SAVE_PROCESS_DONE });
        }
    }
};


export const fetchTenantCurrency = (tenant) => {
    const queryParams = {
        userId: tenant.walletUserId,
        walletId: tenant.walletId,
    };

    return async (dispatch, getState) => {
        dispatch({ type: FETCHING_TENANT_FINANCES_ACTION });
        try {
            const financesResult = await getTenantFinances(queryParams, getState);
            const returnItems = financesResult.data;
            dispatch({ type: FETCH_TENANT_FINANCES_SUCCESS_ACTION, payload: returnItems });
           
        } catch (err) {
            dispatch({ type: FETCH_TENANT_FINANCES_ERROR_ACTION, err });
        }
    };
};
