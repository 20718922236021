import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Form, TextArea, Label } from 'semantic-ui-react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import * as TEST_QNAIRE_ACTIONS from "../../../../actions/testQuestionnaireActions"

export function TestQuestionnaireModal(props) {
    const [state, setState] = useState({
        questionaire: { ...props.questionaire },
        userResponse: '', 
        sentMessages: [],
        questions: [],
        currentQuestionType: '',
        isDisabled: false
    });

    const scrollableContainerRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setState({ ...state, userResponse: props.questionaire.activationPhrase || '' });
    }, [props.questionaire.activationPhrase]);

    useEffect(() => {
        if (state.currentQuestionType === "no_answer") {
            noAnswerQuestion();
        }else if(state.currentQuestionType === "endofqnaire"){
            let qnaireData = {
                PK: `USER#${props.userData[0].userName}`,
                SK: `QNAIRE#${props.questionaire.id}`,
                status: "isDeleted"
            }

            props.deleteTestQnaireAction(qnaireData);
            
            setState(prevState => ({
                ...prevState,
                userqnaire_id: '', 
                userResponse: '',
                currentQuestionType: '',
                isDisabled: true
            }));
        }
    }, [state.currentQuestionType, state.questions]);

    useEffect(() => {
        scrollToBottom();
    }, [state.questions]);

    const questionTypes = [
        { key: 1, text: "Text", value: "val_string", isCompatible: true },
        { key: 2, text: "Paragraph", value: "val_paragraph", isCompatible: true },
        { key: 3, text: "Number", value: "val_num", isCompatible: true },
        { key: 4, text: "Date - DD-MM-YYYY", value: "date_long", isCompatible: true },
        { key: 5, text: "Year - YYYY", value: "date_year", isCompatible: true },
        { key: 6, text: "Multiple Choice Select One", value: "mc_sel_one", isCompatible: true },
        { key: 7, text: "Multiple Select", value: "multi_select", isCompatible: true },
        { key: 8, text: "Yes/No", value: "yes_no", isCompatible: true },
        { key: 9, text: "Scale", value: "scale", isCompatible: true },
        { key: 10, text: "Image Upload", value: "upload_image", isCompatible: true },
        { key: 11, text: "Document Upload", value: "upload_document", isCompatible: true },
        { key: 12, text: "Video Upload", value: "upload_video", isCompatible: true },
        { key: 13, text: "Location", value: "location", isCompatible: true },
        { key: 14, text: "Bullet List", value: "bullet_list", isCompatible: false },
        { key: 15, text: "Number List", value: "number_list", isCompatible: false },
        { key: 16, text: "No Answer", value: "no_answer", isCompatible: true },
        { key: 17, text: "Map", value: "map", isCompatible: false },
        { key: 18, text: "Map Yes/No", value: "map_yes_no", isCompatible: false },
        { key: 19, text: "Link to Questionnaire", value: "link_to_qnaire", isCompatible: true },
        { key: 20, text: "Image ID", value: "image_no_answer", isCompatible: true },
        { key: 21, text: "WhatsApp Template", value: "whatsapp_template", isCompatible: true },
      ];

    let selectedQuestionType = questionTypes.find(
        (type) => type.value === state.currentQuestionType
    );

    const scrollToBottom = () => {
        scrollableContainerRef.current.scrollTop = scrollableContainerRef.current.scrollHeight;
    };

    const handleInputChange = (e, { value }) => {
        setState({ ...state, userResponse: value });
    };

    const handleSubmit = async () => {    
        if (state.userResponse.trim() === '') {
            toast.error('Please enter a message.')
            return;
        }

        setState(prevState => ({
            ...prevState,
            disabled: true
        }));

        await fetchQuestion();
    };

    // Function to fetch the initial question
    const fetchQuestion = async () => {
        setState({ ...state, isDisabled: true });
        let questionData;
        if( !_.isEmpty(state.userqnaire_id) && state.userResponse !== ""){
            const qnaireData = {
                "from_value": props.userData[0].userName,
                "tenant_id": props.tenantId,
                "invoke_environ": "webapp",
                "qnaire_id": state.questionaire.id,
                "userqnaire_id": state.userqnaire_id,
                "answer": state.userResponse
            };
            questionData = await props.getNextQuestionAction(qnaireData, setLoading);
        } else {
            const qnaireData = {
                "from_value": props.userData[0].userName,
                "tenant_id": props.tenantId,
                "invoke_environ": "webapp",
                "qnaire_id": state.questionaire.id,
            };
            questionData = await props.executeStepFunction(qnaireData, setLoading);
        }

        if(questionData == undefined){
            toast.error("Error simulation the questionnaire. Please ensure all questions that are marked as conditional have conditions.")
        }
        else{
            setState(prevState => ({
                ...prevState,
                sentMessages: [...prevState.sentMessages, state.userResponse],
                questions: [
                    ...prevState.questions, 
                    { 
                        text: questionData.next_question, 
                        type: questionData.next_question_type,
                        mcVals:  questionData.mc_vals || []
                    }
                ],
                userqnaire_id: questionData.userqnaire_id || state.userqnaire_id, 
                userResponse: '',
                currentQuestionType: questionData.next_question_type,
                isDisabled: false
            }));
        }
    };

    const noAnswerQuestion = async () => {
        setState({ ...state, isDisabled: true });

        const qnaireData = {
            "from_value": props.userData[0].userName,
            "tenant_id": props.tenantId,
            "invoke_environ": "webapp",
            "qnaire_id": state.questionaire.id,
            "userqnaire_id": state.userqnaire_id,
            "answer": ""
        };

        const questionData = await props.getNextQuestionAction(qnaireData, setLoading);

        if(questionData == undefined){
            toast.error("Error simulation the questionnaire. Please try again or contact support.")
        }
        else{
            setState(prevState => ({
                ...prevState,
                sentMessages: [...prevState.sentMessages, "..."],
                questions: [
                    ...prevState.questions, 
                    { 
                        text: questionData.next_question, 
                        type: questionData.next_question_type,
                        mcVals:  questionData.mc_vals || []
                    }
                ],
                userqnaire_id: state.userqnaire_id, 
                userResponse: '',
                currentQuestionType: questionData.next_question_type,
                isDisabled: false
            }));
        }
    }

    return (
        <div>
            <div>
                <div ref={scrollableContainerRef} style={{ height: '300px', overflowY: 'auto', padding: '10px' }}>
                    {state.sentMessages.map((message, index) => (
                        <React.Fragment key={index}>
                            <div className="chat-bubble user-bubble">{message}</div>
                            {state.questions[index] && (
                                <div className="chat-bubble bot-bubble">
                                    {state.questions[index].text === "endofqnaire" ? "End of Questionnaire" : state.questions[index].text}
                                </div>
                            )}
                            {state.questions[index] && (state.questions[index].type === "mc_sel_one" || state.questions[index].type === "multi_select" || state.questions[index].type === "link_to_qnaire") && (
                                <div className="chat-bubble bot-bubble">
                                    {state.questions[index].mcVals.map((option, optionIndex) => (
                                        <div key={optionIndex}>{optionIndex + 1}: {option}</div>
                                    ))}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="question-type-label" style={{ display: "inline-block", marginLeft: "10px", paddingBottom: "5px" }} >
                {state.currentQuestionType !== "" && state.currentQuestionType !== "no_answer" ? (
                    state.currentQuestionType === "yes_no" ? (
                    <Label color="blue" style={{ fontWeight: "bold" }}>
                        Expecting (Yes or No)
                    </Label>
                    ) : (
                        state.currentQuestionType === "multi_select" ? (
                            <Label color="blue" style={{ fontWeight: "bold" }}>
                                Expected ({selectedQuestionType?.text || "Unknown"})  (Seperate with a comma (1,2,3))
                            </Label>
                        ) : (
                            state.currentQuestionType === "upload_image" || state.currentQuestionType === "upload_document" || state.currentQuestionType === "upload_video" || state.currentQuestionType === "location" ? (
                                <Label color="blue" style={{ fontWeight: "bold" }}>
                                    Expected ({selectedQuestionType?.text || "Unknown"})  (Just enter text)
                                </Label>
                            ) : (
                                <Label color="blue" style={{ fontWeight: "bold" }}>
                                    Expected ({selectedQuestionType?.text || "Unknown"})
                                </Label>
                            )
                        )
                    )
                ) : (
                    <div></div>
                )}
            </div>
            <Form reply>
                <TextArea
                    value={state.userResponse}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit();
                        }
                    }}
                    placeholder="Type your response..."
                    style={{ minHeight: 15, marginBottom: "10px" }}
                />
                <Button loading={loading} floated='right' content='Send' labelPosition='left' icon='envelope' primary onClick={handleSubmit} disabled={state.userResponse.trim() === '' || state.disabled} />
            </Form>
        </div>
    );  
}

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.authUserRoles.userRoles,
        tenantId: state.tenantManagement.activeTenant.tenantId,
    }
}

export default connect(
    mapStateToProps,
    (dispatch, ownProps) => ({
        executeStepFunction: (data, setLoading) => dispatch(TEST_QNAIRE_ACTIONS.executeStepFunctionAction(data, setLoading)),
        getNextQuestionAction: (data, setLoading) => dispatch(TEST_QNAIRE_ACTIONS.getNextQuestionAction(data, setLoading)),
        deleteTestQnaireAction: (qnaireData, userQnaireData) => dispatch(TEST_QNAIRE_ACTIONS.deleteTestQnaireAction(qnaireData, userQnaireData)),
    })
)(TestQuestionnaireModal);
