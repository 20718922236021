import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from "semantic-ui-react";
import _ from 'lodash';
import './App.css'
import * as REGISTRATION_ACTIONS from '../actions/registrationActions';

class TopMenu extends Component {
  state = {
    dropdownMenuStyle: {
      display: "none"
    },
    activeItem: null,
  };

  handleItemClick = (item) => {
    this.setState((prevState) => ({
      activeItem: prevState.activeItem === item ? null : item
    }));
  };

  hasPermissions(category) {
    return !_.isEmpty(this.props.userPermissions) && this.props.userPermissions.hasOwnProperty(category)
  }

  isAdmin() {
    return this.hasPermissions('Other') && this.props.userPermissions['Other'].actions.admin
  }

  canReadQuestionnaires() {
    return this.hasPermissions('Questionnaires') && this.props.userPermissions['Questionnaires'].actions.READ || this.isAdmin()
  }

  canReadPPTReferences() {
    return this.hasPermissions('PTT References') && this.props.userPermissions['PTT References'].actions.READ || this.isAdmin()
  }

  canReadBusinessRule() {
    return this.hasPermissions('Business Rules') && this.props.userPermissions['Business Rules'].actions.READ || this.isAdmin()
  }

  canReadBRTypes() {
    return this.hasPermissions('Business Rule Types') && this.props.userPermissions['Business Rule Types'].actions.READ || this.isAdmin()
  }

  canReadActivationRule() {
    return this.hasPermissions('Distribution Rules') && this.props.userPermissions['Distribution Rules'].actions.READ || this.isAdmin()
  }

  canReadKmlFile() {
    return this.hasPermissions('KML Uploads') && this.props.userPermissions['KML Uploads'].actions.READ || this.isAdmin()
  }

  canManageUsers() {
    return this.hasPermissions('Manage Users') && this.props.userPermissions['Manage Users'].actions.READ || this.isAdmin()
  }

  canManageRoles() {
    return this.hasPermissions('Manage Roles') && this.props.userPermissions['Manage Roles'].actions.READ || this.isAdmin()
  }

  canManageTenant() {
    return this.hasPermissions('Manage Tenant') && this.props.userPermissions['Manage Tenant'].actions.READ || this.isAdmin()
  }

  canManageTenants() {
    return this.hasPermissions('Manage Tenants') && this.props.userPermissions['Manage Tenants'].actions.READ || this.isAdmin()
  }

  canManageGlobalPrices() {
    return this.hasPermissions('Manage Global Pricing') && this.props.userPermissions['Manage Global Pricing'].actions.READ || this.isAdmin()
  }

  canViewDashboard() {
    return this.hasPermissions('Dashboard') && this.props.userPermissions['Dashboard'].actions.READ || this.isAdmin()
  }

  canReadCVSearch() {
    return this.hasPermissions('CV Search') && this.props.userPermissions['CV Search'].actions.READ || this.isAdmin()
  }

  canReadCVSearchLog() {
    return this.hasPermissions('CV Search Logs') && this.props.userPermissions['CV Search Logs'].actions.READ || this.isAdmin()
  }

  canReadOwnedCVs() {
    return this.hasPermissions('Owned CVs') && this.props.userPermissions['Owned CVs'].actions.READ || this.isAdmin()
  }

  canExportData() {
    return this.hasPermissions('Export Data') && this.props.userPermissions["Export Data"].actions.READ || this.isAdmin()
  }

  canReadFinanceData() {
    return this.hasPermissions('Financial Transactions') && this.props.userPermissions["Financial Transactions"].actions.READ || this.isAdmin()
  }

  canReadAdutiTrailData() {
    return this.hasPermissions('Audit Trail') && this.props.userPermissions["Audit Trail"].actions.READ || this.isAdmin()
  }

  canReadUserGroups() {
    return this.hasPermissions('User Groups') && this.props.userPermissions['User Groups'].actions.READ || this.isAdmin();
  };

  render() {

    const { activeItem } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '5px' }}>
          <Menu borderless vertical fluid>
            {this.canViewDashboard() &&
              <Menu.Item as="a" href={`/Dashboard/View Dashboard`} className='menu-item'>View Dashboard</Menu.Item>
            }

            {this.canReadQuestionnaires() &&
              <Menu.Item as="a" href={`/Questionnaires`} className='menu-item'>Questionnaires</Menu.Item>
            }

            {(this.canReadCVSearch() || this.canReadPPTReferences() || this.canReadCVSearchLog() || this.canReadOwnedCVs()) &&
              <Menu.Item
                onClick={() => this.handleItemClick('GeneralActions')}
                className='menu-item'
              >
                General Actions
                {activeItem === 'GeneralActions' && (
                  <Menu.Menu style={{ borderTop: "1px solid #3A95A4", margin: "10px 0" }}>
                    {this.canReadCVSearch() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/General Actions/CVSearch`}>CV Search</Menu.Item>
                    }
                    {this.canReadCVSearchLog() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/General Actions/CVSearchLogs`}>CV Search Logs</Menu.Item>
                    }
                    {this.canReadOwnedCVs() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/General Actions/OwnedCVs`}>Owned CVs</Menu.Item>
                    }
                    {this.canExportData() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/General/Export Data`}>Export Data</Menu.Item>
                    }
                    {this.canReadPPTReferences() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/General Actions/PPTReferences`}>PPT References</Menu.Item>
                    }
                  </Menu.Menu>
                )}
              </Menu.Item>
            }

            {(this.canReadBusinessRule() || this.canReadBRTypes()) && (
              <Menu.Item
                onClick={() => this.handleItemClick('BusinessRules')}
                className='menu-item'
              >
                Business Rules
                {activeItem === 'BusinessRules' && (
                  <Menu.Menu style={{ borderTop: "1px solid #3A95A4", margin: "10px 0" }}>
                    {this.canReadBusinessRule() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Business Rules/Business Rules`}>Business Rules</Menu.Item>
                    }
                    {this.canReadBRTypes() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Business Rules/Business Rule Types`}>Business Rule Types</Menu.Item>
                    }
                  </Menu.Menu>
                )}
              </Menu.Item>
            )}

            {(this.canReadActivationRule() || this.canReadKmlFile() || this.canReadUserGroups()) && (
              <Menu.Item
                onClick={() => this.handleItemClick('DistributionRules')}
                className='menu-item'
              >
                Distribution Rules
                {activeItem === 'DistributionRules' && (
                  <Menu.Menu style={{ borderTop: "1px solid #3A95A4", margin: "10px 0" }}>
                    {this.canReadActivationRule() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Distribution Rules/Distribution Rules`}>Distribution Rules</Menu.Item>
                    }
                    {this.canReadKmlFile() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Distribution Rules/KML Uploads`}>KML Uploads</Menu.Item>
                    }
                    {this.canReadUserGroups() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Distribution Rules/User Groups`}>User Groups</Menu.Item>
                    }
                  </Menu.Menu>
                )}
              </Menu.Item>
            )}

            {(this.canManageUsers() || this.canManageRoles() || this.canManageTenant() || this.canManageTenants() || this.canManageGlobalPrices() || this.canExportData() || this.canReadFinanceData() || this.canReadAdutiTrailData()) && (
              <Menu.Item
                onClick={() => this.handleItemClick('Admin')}
                className='menu-item'
              >
                Admin
                {activeItem === 'Admin' && (
                  <Menu.Menu style={{ borderTop: "1px solid #3A95A4", margin: "10px 0" }}>
                    {this.canReadAdutiTrailData() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Audit Trail`}>Audit Trail</Menu.Item>
                    }
                    {this.canReadFinanceData() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Tenant Finances`}>Tenant Financials</Menu.Item>
                    }
                    {this.canManageTenant() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Tenant Pricing`}>Tenant Pricing</Menu.Item>
                    }
                    {this.canManageTenant() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Manage Tenant`}>Manage Tenant Information</Menu.Item>
                    }
                    {this.canManageRoles() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Manage Roles`}>Manage Roles</Menu.Item>
                    }
                    {this.canManageUsers() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Manage Users`}>Manage Users</Menu.Item>
                    }
                    {this.props.isRoot && this.canManageTenants() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Manage Tenants`}>Tenant Management</Menu.Item>
                    }
                    {this.props.isRoot && this.canManageGlobalPrices() &&
                      <Menu.Item className='menu-subitem' as="a" href={`/Admin/Manage Global Prices`}>Global Parameters</Menu.Item>
                    }
                  </Menu.Menu>
                )}
              </Menu.Item>
            )}

            {this.props.isRoot && this.isAdmin() && (
              <Menu.Item
                onClick={() => this.handleItemClick('ActiveTenant')}
                className='menu-item'
              >
                Active Tenant: {this.props.activeTenant.companyName}
                {activeItem === 'ActiveTenant' && (
                  <Menu.Menu style={{ borderTop: "1px solid #3A95A4", margin: "10px 0" }}>
                    <Menu.Item
                      className='menu-subitem'
                      style={{ fontSize: "12px" }}
                      key={this.props.sourceTenant.id}
                      active={this.props.activeTenant.id === this.props.sourceTenant.id}
                      onClick={() => this.props.setActiveTenant(this.props.sourceTenant)}
                    >
                      {this.props.sourceTenant.companyName} (Source)
                    </Menu.Item>

                    {this.props.allTenants
                      .filter(tenant => tenant.id !== this.props.sourceTenant.id && tenant.status !== "disabled")
                      .sort((a, b) => a.companyName.localeCompare(b.companyName))
                      .map((tenant) => (
                        <Menu.Item
                          className='menu-subitem'
                          key={tenant.id}
                          active={this.props.activeTenant.id === tenant.id}
                          onClick={() => this.props.setActiveTenant(tenant)}
                        >
                          {tenant.companyName}
                        </Menu.Item>
                      ))}
                  </Menu.Menu>
                )}
              </Menu.Item>
            )}

          </Menu>
        </div>
      </div>
    );

  }
}


const mapStateToProps = (state) => {
  return {
    isRoot: state.tenantManagement?.sourceTenant?.isRoot === true,
    sourceTenant: state.tenantManagement.sourceTenant,
    activeTenant: state.tenantManagement.activeTenant,
    allTenants: state.tenantManagement.allTenants, userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    userPermissions: _.isEmpty(state.authUserRoles.permissions) ? {} : state.authUserRoles.permissions,
    isPermissionsFetched: state.isPermissionsFetched === true,
  };
}

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    setActiveTenant: (tenant) => dispatch(REGISTRATION_ACTIONS.setActiveTenantAction(tenant)),
  })
)(TopMenu);