import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Dropdown, Form } from "semantic-ui-react";
import * as QUESTION_ACTIONS from '../../../../actions/questionActions'

class QuestionSelectComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
		};
	}

	static defaultProps = {
		questions: [],
		sectionId: null,
		excludeId: null,
		selectedQuestionId: null,
		onQuestionSelectedChanged: (value) => { }
	}

	componentDidMount() { }

	componentDidUpdate(prevProps, prevState) {
		if ((!_.isEmpty(this.props.sectionId) && _.isEmpty(prevProps.sectionId))
			|| (!_.isEmpty(this.props.sectionId) && this.props.sectionId !== prevProps.sectionId)) {
			this.updateSelectedQuestion(null); //We no longer have anything selected
			this.props.fetchQuestionsAction(this.props.sectionId);

		}

		if (this.state.selectedQuestionId !== this.props.selectedQuestionId) {
			this.setState((prevState => ({ selectedQuestionId: this.props.selectedQuestionId })))
		}
	}

	questionList() {
		return [
			{ key: 'none', text: 'None', value: null },
			...this.props.questions.map((question) => ({
				key: question.id,
				text: question.question,
				value: question.id,
			}))
		];
	}

	updateSelectedQuestion(value) {
		this.setState((prevState => ({ ...prevState, selectedQuestionId: value })));
		this.props.onQuestionSelectedChanged(value);
	}

	render() {
		return (
			<Form.Field>
				<label>Depends on question (Optional): </label>
				<Dropdown
					placeholder='Select a question'
					defaultValue={this.props.selectedQuestionId}
					fluid
					selection
					search
					options={this.questionList()}
					onChange={(e, { value }) => {
						this.updateSelectedQuestion(value);
					}} />
			</Form.Field>
		);
	}

}

const mapStateToProps = (state, ownprops) => {
	return {
		questions: _.isEmpty(state.questions) ? [] : Object.values(state.questions).filter(question => question.sectionId === ownprops.sectionId && question.id !== ownprops.excludeId && question.status !== 'isDeleted')
	};
}

export default connect(
	// map state to props
	mapStateToProps,
	// map dispatch to props
	(dispatch, ownProps) => ({
		fetchQuestionsAction: (sectionId) => dispatch(QUESTION_ACTIONS.fetchQuestionsAction(sectionId)),
	})
)(QuestionSelectComponent);