import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// import { v4 as uuidv4 } from 'uuid';
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form, Checkbox, Button, Icon, Divider, Confirm } from "semantic-ui-react";
import * as QUESTIONNAIRE_ACTIONS from "../../../../actions/questionaireActions";
import * as SECTION_ACTIONS from "../../../../actions/sectionActions";
import * as CONDITION_ACTIONS from '../../../../actions/conditionActions';
import * as LOOKUPS_ACTIONS from "../../../../actions/lookupsActions";
import ConditionComponent from "./ConditionComponent";
export function SectionDetails(props) {
  const [state, setState] = useState({
    questionaire: { ...props.questionaire },
    section: { ...props.section },
    sections: Array.isArray(props.sections) ? props.sections : [],
    condition: !_.isEmpty(props.condition) ? { ...props.condition } : {},
    showCondition: props.section != null && props.section.isConditional == true,
    showConfirmIsActive: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    props.fetchLookupListAction();
    props.fetchCondition(props.section.id, props.section.tenantId);
  }, []);

  useEffect(() => {
    if (
      (!_.isEmpty(props.section.sectionId) &&
        _.isEmpty(state.section.sectionId)) ||
      (!_.isEmpty(props.section.sectionId) &&
        props.section.sectionId !== state.section.sectionId)
    ) {
      props.fetchCondition(props.section.id);
    }
    if (_.isEmpty(state.condition) && !_.isEmpty(props.condition)) {
      setState((state) => ({
        ...state,
        condition: props.condition,
        showCondition: !_.isEmpty(props.condition),
      }));
    }
  },
    [props.section.sectionId]
  );

  const confirmDeactivate = () => {
    let contentMessage = "The questionnaire is currently active or under review. Editing any part of the questionnaire will result in its deactivation, requiring it to undergo the review process again.";

    return (
      <Confirm
        header='Warning:'
        content={contentMessage}
        open={state.showConfirmIsActive}
        onCancel={() => setState((state) => ({ ...state, showConfirmIsActive: false }))}
        onConfirm={() => {
          var section = {
            ...state.section,
            sequence: state.section.sequence ?? 1,
          };

          setState((prevState) => ({
            ...prevState,
            section: section,
          }));

          props.deactiveQuestionaireAction(props.questionaire[0]);
          props.commitSection(state.section, state.condition, props.isNew, props.section.tenantId);

          if (!_.isEmpty(state.condition)) {
            if (state.showCondition === true) {
              state.condition.questionnaireId = state.section.questionnaireId;
              state.condition.sectionId = state.section.id;
              state.condition.questionId = null;
            }
          } else {
            if (state.showCondition === true) {
              props.commitCondition(
                _.omit(state.condition, ["isNew"]),
                state.condition.isNew === true
              );
            }
          }

          if (_.isEmpty(props.onComplete)) {
            props.onComplete();
          }
        }}
      />
    );
  }

  const onSubmit = (data) => {
    const isQuestionnaireActive = props.questionaire[0].status == "active" || props.questionaire[0].status == "underReview";

    if (isQuestionnaireActive) {
      setState((state) => ({ ...state, showConfirmIsActive: true }));
    } else {

      const { condition } = state;
      const isSectionSelected = !!condition.targetSectionId;
      const isOperatorSelected = !!condition.conditionOperator;
      const isValueProvided = !!condition.value;

      if (state.section.isConditional === true && (!isSectionSelected || !isOperatorSelected || !isValueProvided)) {
        toast.error("Please complete all required fields in the condition section.");
        return;
      }

      const newSequence = Number(state.section.sequence);
      const isDuplicate = newSequence !== "" && state.sections.some(section => section.id !== state.section.id && section.sequence === newSequence);

      if (isDuplicate) {
        toast.error("The entered sequence number is already in use.")
      } else {
        var section = {
          ...state.section,
          sequence: state.section.sequence ?? 1,
        };

        setState((prevState) => ({
          ...prevState,
          section: section,
        }));

        props.commitSection(state.section, state.condition, props.isNew, props.section.tenantId);

        if (!_.isEmpty(state.condition)) {
          if (state.showCondition === true) {
            state.condition.questionnaireId = state.section.questionnaireId;
            state.condition.sectionId = state.section.id;
            state.condition.questionId = null;
          }
        } else {
          if (state.showCondition === true) {
            props.commitCondition(
              _.omit(state.condition, ["isNew"]),
              state.condition.isNew === true
            );
          }
        }

        if (_.isEmpty(props.onComplete)) {
          props.onComplete();
        }
      }
    }
  };

  const showCondition = () => {
    setState((state) => ({ ...state, section: { ...state.section, isConditional: !state.showCondition ? true : false }, showCondition: !state.showCondition }))
  }

  const handleConditionEdit = (condition) => {
    setState((state) => ({
      ...state,
      condition: condition,
    }));
  };

  const onHandleChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      section: {
        ...prevState.section,
        [key]: value,
      },
    }));
  };

  return (
    <Form size="small" onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "20px" }}>
      {confirmDeactivate()}
      <Form.Field>
        <label>Section Name:</label>
        <input
          className={errors.section_name ? "invalid-input-field" : ""}
          {...register("section_name", { required: true, maxLength: 50, })}
          placeholder="Section Name"
          value={state.section.name}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              section: { ...prevState.section, name: e.target.value },
            }));
          }}
        />

        {errors.section_name && errors.section_name.type === "required" && (
          <p className="validation-error">* Please enter a name</p>
        )}
        {errors.section_name && errors.section_name.type === "pattern" && (
          <p className="validation-error">
            * May only contain alphabetical characters
          </p>
        )}
        {errors.section_name && errors.section_name.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 50 characters long
          </p>
        )}
      </Form.Field>
      <Form.Field>
        <label>Section Display Name:</label>
        <input
          className={errors.section_display_name ? "invalid-input-field" : ""}
          {...register("section_display_name", {
            required: true,
            maxLength: 50,
          })}
          placeholder="Section Display Name"
          value={state.section.displayName}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              section: { ...prevState.section, displayName: e.target.value },
            }));
          }}
        />
        {errors.section_display_name &&
          errors.section_display_name.type === "required" && (
            <p className="validation-error">* Please enter a display name</p>
          )}
        {errors.section_display_name &&
          errors.section_display_name.type === "pattern" && (
            <p className="validation-error">
              * May only contain alphabetical characters
            </p>
          )}
        {errors.section_display_name &&
          errors.section_display_name.type === "maxLength" && (
            <p className="validation-error">
              * Name may not be more than 50 characters long
            </p>
          )}
      </Form.Field>
      <Form.Field>
        <label>Extra Info:</label>
        <input
          className={errors.extra_info ? "invalid-input-field" : ""}
          {...register("extra_info", {
            required: false,
            maxLength: 50,
          })}
          placeholder="Extra Info"
          value={state.section.extraInfo}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              section: { ...prevState.section, extraInfo: e.target.value },
            }));
          }}
        />
        {errors.extra_info && errors.extra_info.type === "required" && (
          <p className="validation-error">* Please enter extra info</p>
        )}
        {errors.extra_info && errors.extra_info.type === "pattern" && (
          <p className="validation-error">
            * May only contain alphabetical characters
          </p>
        )}
        {errors.extra_info && errors.extra_info.type === "maxLength" && (
          <p className="validation-error">
            * Name may not be more than 50 characters long
          </p>
        )}
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Mandatory"
          defaultChecked={!!state.section.isMandatory}
          checked={!!state.section.isMandatory}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              section: {
                ...prevState.section,
                isMandatory: !prevState.section.isMandatory,
              },
            }));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label="Is Active"
          defaultChecked={!!state.section.isActive}
          checked={!!state.section.isActive}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              section: {
                ...prevState.section,
                isActive: !prevState.section.isActive,
              },
            }));
          }}
        />
      </Form.Field>
      <Form.Field>
        <label>Sequence:</label>
        <input
          placeholder="1"
          type="number"
          min="1"
          max="100"
          value={state.section.sequence}
          onChange={(e) => {
            setState((prevState) => ({
              ...prevState,
              section: { ...prevState.section, sequence: e.target.value },
            }));
          }}
        />
      </Form.Field>
      <Form.Field>
        <Checkbox
          toggle
          label=" Section has condition?"
          defaultChecked={!!state.section.isConditional}
          checked={!!state.section.isConditional}
          onChange={() => {
            setState((prevState) => ({
              ...prevState,
              section: {
                ...prevState.section,
                isConditional: !prevState.section.isConditional,
              },
            }));
            showCondition();
          }}
        />
      </Form.Field>
      {state.showCondition && (
        <ConditionComponent
          targetSectionId={state.condition.targetSectionId}
          targetQuestionId={state.condition.targetQuestionId}
          questionaire={state.questionaire[0]}
          sectionId={state.section.id}
          isSectionCondition={true}
          onConditionEdit={handleConditionEdit}
        />
      )}
      <Divider />
      <Button.Group floated="right">
        <Button
          className="danger"
          onClick={() => {
            if (_.isEmpty(props.onComplete)) {
              props.onComplete();
            }
          }}
        >
          <Icon name="remove" /> Cancel
        </Button>
        <Button type="submit" className="success">
          <Icon name="checkmark" /> Ok
        </Button>
      </Button.Group>
    </Form>
  );
}

SectionDetails.defaultProps = {
  section: {},
  questionaireId: 0,
  sectionId: 0,
};

const mapStateToProps = (state, ownProps) => {
  let section = {};
  let condition = {};
  let isNew = false;
  let newSection = {
    name: "",
    displayName: "",
    extraInfo: "",
    isMandatory: false,
    isConditional: false,
    isActive: true,
    canRepeat: false,
    sequence: 1,
    isUserCreated: true,
    questionaireId: ownProps.questionaire.id,
    sectionId: ownProps.section.sectionId,
    tenantId: ownProps.questionaire.tenantId,
  };

  let questionaire = state.questionaires.filter((questionaire) => questionaire.id === ownProps.section.questionaireId || questionaire.id === ownProps.questionaire.id)

  if (!ownProps.section.hasOwnProperty("id")) {
    section = newSection;
    isNew = true;
  } else {
    section = _.isEmpty(state.sections)
      ? newSection
      : Object.values(
        state.sections.filter((section) => section.id === ownProps.section.id)
      )[0];
    isNew = false;
  }

  if (ownProps.section.id != undefined) {
    for (let temp in state.conditions) {
      if (ownProps.section.id === state.conditions[temp].sectionId) {
        condition = state.conditions[temp];
      }

    }
    isNew = false;
  }

  return {
    section: section,
    // sections: sections,
    condition: condition,
    isNew: isNew,
    questionaire: questionaire,
    tenantId: state.tenantManagement.activeTenant.tenantId,
  };
};

export default connect(
  // map state to props
  mapStateToProps,
  // map dispatch to props
  (dispatch, ownProps) => ({
    fetchSectionsAction: (questionaireId) => dispatch(SECTION_ACTIONS.fetchSectionsAction(questionaireId)),
    deactiveQuestionaireAction: (questionaire) => dispatch(QUESTIONNAIRE_ACTIONS.deactiveQuestionaireAction(questionaire)),
    commitSection: (section, condition, isNew, tenantId) => dispatch(SECTION_ACTIONS.sectionSaveAction(section, condition, isNew, tenantId)),
    fetchCondition: (sectionId, tenantId) => dispatch(CONDITION_ACTIONS.fetchConditionsAction(sectionId, tenantId, true)),
    fetchLookupListAction: () => dispatch(LOOKUPS_ACTIONS.fetchLookupsAction()),
  })
)(SectionDetails);
